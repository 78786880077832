import { RGBAFormat, RGBFormat } from "./constants";
import { Texture } from "./types";

function loadImage(url, onLoad, onError) {
    const image = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "img"
    );

    function onImageLoad() {
      image.removeEventListener("load", onImageLoad, false);
      image.removeEventListener("error", onImageError, false);

      if (onLoad) onLoad(image);
    }

    function onImageError(event) {
      image.removeEventListener("load", onImageLoad, false);
      image.removeEventListener("error", onImageError, false);

      if (onError) onError(event);
    }

    image.addEventListener("load", onImageLoad, false);
    image.addEventListener("error", onImageError, false);

    image.src = url;
}

export function loadTexture(url, onLoad, onError) {
    const texture = new Texture();

    loadImage(
      url,
      (image) => {
        texture.image = image;

        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        const isJPEG =
          url.search(/\.jpe?g($|\?)/i) > 0 ||
          url.search(/^data\:image\/jpeg/) === 0;

        texture.format = isJPEG ? RGBFormat : RGBAFormat;
        texture.needsUpdate = true;

        if (onLoad !== undefined) {
          onLoad(texture);
        }
      },
      onError
    );

    return texture;
}