import { Stack, Tooltip } from "@mui/material";
import React, { Component } from "react";

type Props = {
  children;
  onClick?: Function;
  id?: string;
  className?: string;
  passive?: boolean;
  disabled?: boolean;
  active?: boolean;
  tooltip?: string;
  onContextMenu?: any;
  value?: string | number;
};

type State = {};

export default class MenuItem extends Component<Props, State> {
  myRef;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    const {
      children,
      onClick,
      id,
      className,
      active,
      passive,
      tooltip,
      disabled,
      onContextMenu,
      value,
    } = this.props;
    const component = (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`sideMenuItem${!!passive || !!disabled ? "" : " hoverable"}${
          active ? " active" : ""
        }${className ? ` ${className}` : ""}${disabled ? " disabled" : ""}`}
        onClick={(e) => {
          e.target = this.myRef.current;
          !!onClick && !disabled && onClick(e);
        }}
        id={id}
        ref={this.myRef}
        onContextMenu={onContextMenu ? onContextMenu : undefined}
        data-value={value ? value : undefined}
        key={id}
      >
        {children}
      </Stack>
    );

    if (tooltip) {
      return (
        <Tooltip disableInteractive arrow placement="right" title={tooltip}>
          {component}
        </Tooltip>
      );
    }

    return component;
  }
}
