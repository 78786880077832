import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Loading } from "react/views";
import { BASE_URI } from "config";
import { validateEmail } from "utils";
import { Check, Clear } from "@mui/icons-material";

export default function Reset() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tooMany = searchParams.get("too_many_attempts");
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [verificationCode, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordVerification, setPasswordVerification] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [sendingEmail, setEmailSending] = useState<boolean>(false);
  const [sendingPassword, setSendingPassword] = useState<boolean>(false);

  function onEmailSubmitted(e) {
    e.preventDefault();
    setErrorMessage("");
    if (!validateEmail(email)) {
      setErrorMessage("Email is not valid");
      return;
    }
    setEmailSending(true);
    axios
      .post(`${BASE_URI}/auth/send_reset_email`, { email })
      .then((response) => {
        if (response.status === 200) {
          setEmailSending(false);
          setEmailSent(true);
        }
      })
      .catch((err) => {
        setEmailSending(false);
        setErrorMessage(err.response.data);
      });
  }

  function onPasswordSubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    setSendingPassword(true);
    axios
      .post(`${BASE_URI}/auth/reset_password`, {
        email,
        verificationCode,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          setSendingPassword(false);
          navigate(`/?message=${response.data}`);
        }
      })
      .catch((err) => {
        setSendingPassword(false);
        setErrorMessage(err.response.data);
      });
  }

  useEffect(() => {
    if (tooMany) {
      setErrorMessage("Too many attempts has been made to login.");
    }
  }, []);

  const emailValid = validateEmail(email);
  const passwordsIdentical = password === passwordVerification;
  const passwordStatus = {
    length: password.length >= 8,
    number: /[0-9]/.test(password),
    letters: /[a-z]/.test(password) && /[A-Z]/.test(password),
    characters: /[@$!%*#?^+-]/.test(password),
  };
  const passwordValid =
    passwordStatus.length &&
    passwordStatus.number &&
    passwordStatus.letters &&
    passwordStatus.characters;
  const codeValid =
    verificationCode.length === 6 && /[0-9]{6}/.test(verificationCode);
  return (
    <>
      {errorMessage && (
        <Alert
          severity="error"
          sx={{ mb: 1 }}
          onError={() => setErrorMessage("")}
        >
          {errorMessage}
        </Alert>
      )}
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>

      {!emailSent ? (
        <Box
          component="form"
          onSubmit={onEmailSubmitted}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="verificationCode">Email Address</InputLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              disabled={emailSent || sendingEmail}
              value={email}
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              error={!emailValid && email.length > 0}
            />
          </FormControl>

          {!emailSent && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              endIcon={sendingEmail && <Loading relative size={20} />}
              sx={{ mb: 2 }}
              disabled={sendingEmail || emailSent || !emailValid}
            >
              {sendingEmail ? "Sending" : "Send"} Verification Code
            </Button>
          )}
        </Box>
      ) : (
        <Typography textAlign="center">{email}</Typography>
      )}
      {emailSent && (
        <>
          <Divider sx={{ width: "100%", mt: 2 }} />
          <Box
            component="form"
            onSubmit={onPasswordSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Stack spacing={2} mb={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="verificationCode">
                  Verification Code
                </InputLabel>
                <Input
                  onChange={(e) => setCode(e.target.value)}
                  disabled={sendingPassword}
                  value={verificationCode}
                  margin="dense"
                  required
                  fullWidth
                  id="verificationCode"
                  name="verificationCode"
                  error={!codeValid && verificationCode.length > 0}
                />
              </FormControl>

              <Tooltip
                placement="right"
                arrow
                disableInteractive
                open={true}
                title={
                  <Stack spacing={1}>
                    <Typography fontSize="small">
                      The password should have
                    </Typography>
                    <Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        {passwordStatus.length ? (
                          <Check htmlColor="green" fontSize="small" />
                        ) : (
                          <Clear htmlColor="red" fontSize="small" />
                        )}
                        <Typography fontSize="small">
                          8 or more characters
                        </Typography>
                      </Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        {passwordStatus.letters ? (
                          <Check htmlColor="green" fontSize="small" />
                        ) : (
                          <Clear htmlColor="red" fontSize="small" />
                        )}
                        <Typography fontSize="small">
                          A small and large letter
                        </Typography>
                      </Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        {passwordStatus.number ? (
                          <Check htmlColor="green" fontSize="small" />
                        ) : (
                          <Clear htmlColor="red" fontSize="small" />
                        )}
                        <Typography fontSize="small">A number</Typography>
                      </Stack>
                      <Stack spacing={1} direction="row" alignItems="center">
                        {passwordStatus.characters ? (
                          <Check htmlColor="green" fontSize="small" />
                        ) : (
                          <Clear htmlColor="red" fontSize="small" />
                        )}
                        <Typography fontSize="small">
                          A special character (@$!%*#?&*.,+-_)
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                }
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    margin="dense"
                    required
                    fullWidth
                    id="password"
                    type="password"
                    name="password"
                    autoComplete="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    disabled={sendingPassword}
                  />
                </FormControl>
              </Tooltip>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="verifyPassword">
                  Confirm Password
                </InputLabel>
                <Input
                  margin="dense"
                  required
                  fullWidth
                  id="verifyPassword"
                  type="password"
                  name="verifyPassword"
                  autoComplete="verifyPassword"
                  onChange={(e) => setPasswordVerification(e.target.value)}
                  value={passwordVerification}
                  error={!passwordsIdentical}
                  disabled={sendingPassword}
                />
              </FormControl>
            </Stack>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              endIcon={sendingPassword && <Loading relative size={20} />}
              disabled={
                sendingPassword ||
                !passwordValid ||
                !codeValid ||
                !passwordsIdentical
              }
            >
              {sendingPassword ? "Saving" : "Save"} password
            </Button>
          </Box>
        </>
      )}
      {emailSent && (
        <Button
          fullWidth
          variant="text"
          sx={{ mt: 1 }}
          endIcon={sendingEmail && <Loading relative size={20} />}
          disabled={sendingEmail}
          onClick={onEmailSubmitted}
        >
          Send code again
        </Button>
      )}
      <Link to="/" style={{ color: "#0078EB" }}>
        Go back to login
      </Link>
    </>
  );
}
