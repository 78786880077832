import { Matrix4, Quaternion, Vector3 } from "potree/mathtypes";
import { Color } from "potree/rendering/types";
import { SQLineBuffer } from "./buffer";

export class LineRenderer {
    active = true;
    #viewer;

    #lineProgram = null;
    #modelViewMatrixLocation = null;
    #thicknessLocation = null;
    #diffuseLocation = null;

    entryLocationColor = new Color(255.0 / 255.0, 140.0 / 255, 0 / 255.0);

    #imageFrameGeometry;
    #imageFrameThickness = 0.1;

    #beamGeometry = null;
    #beamThickness = 1.2;

    constructor(viewer) {
      this.#viewer = viewer;

      viewer.initializedPromise.then(() => {
        const gl = this.#viewer.gl;
        this.#lineProgram = this.#viewer.shaderCache.getProgram(gl, "new_line.vert", "new_line.frag");

        this.#modelViewMatrixLocation = gl.getUniformLocation(this.#lineProgram, "modelViewMatrix");
        this.#thicknessLocation = gl.getUniformLocation(this.#lineProgram, "thickness");
        this.#diffuseLocation = gl.getUniformLocation(this.#lineProgram, "diffuse");
      });

      const frameWidth = 0.56, frameHeight = 0.56;
      this.#imageFrameGeometry = new SQLineBuffer([
        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(-frameWidth, frameHeight, 0),
        new Vector3(-frameWidth, frameHeight, 0),

        new Vector3(-frameWidth, -frameHeight, 0),
        new Vector3(-frameWidth, -frameHeight, 0),
        new Vector3(frameWidth, -frameHeight, 0),
        new Vector3(frameWidth, -frameHeight, 0),

        new Vector3(frameWidth, frameHeight, 0),
        new Vector3(frameWidth, frameHeight, 0),
      ]);

      this.#beamGeometry = new SQLineBuffer([
        new Vector3(0, 0, 3000),
        new Vector3(0, 0, -1000),
      ]);
    }

    render(gl, camera) {
      if(!this.active || !this.#viewer.epsgResolved) {
        return;
      }

      const targetLocation = this.#viewer.targetLocation;
      const targetLocationType = targetLocation.type;
      if(targetLocationType === "NONE") {
        return;
      }

      gl.useProgram(this.#lineProgram);
      gl.disable(gl.CULL_FACE);

      const projectionMatrixLocation = gl.getUniformLocation(this.#lineProgram, "projectionMatrix");
      gl.uniformMatrix4fv(projectionMatrixLocation, false, camera.projectionMatrix.elements);
      const aspectLocation = gl.getUniformLocation(this.#lineProgram, "aspect");
      gl.uniform1f(aspectLocation, camera.aspect);

      switch(targetLocationType) {
        case "IMAGE":
          {
            // DRAW IMAGE BOX.
            const marked_image = this.#viewer.getImageObjectTool().images[targetLocation.image_id];
            if(marked_image) {
              gl.uniformMatrix4fv(this.#modelViewMatrixLocation, false, marked_image.modelViewMatrix.elements);
              gl.uniform1f(this.#thicknessLocation, this.#imageFrameThickness);

              const color = this.entryLocationColor;
              gl.uniform4f(this.#diffuseLocation, color.r, color.g, color.b, 1.0);

              this.drawLines(gl, this.#imageFrameGeometry);
            }
            break;
          }
        case "COORD":
          {
            // DRAW LINE
            if(!targetLocation.cached_pos) {
              const [x, y] = this.#viewer.convertWGS84toLidar(targetLocation.lng, targetLocation.lat, 0);
              const matrix = new Matrix4().compose(new Vector3(x, y, 0), new Quaternion(), new Vector3(1.0, 1.0, 1.0));

              targetLocation.cached_pos = matrix;
            }

            const modelViewMatrix = new Matrix4().multiplyMatrices(
              camera.matrixWorldInverse,
              targetLocation.cached_pos
            );

            gl.uniformMatrix4fv(this.#modelViewMatrixLocation, false, modelViewMatrix.elements);
            gl.uniform1f(this.#thicknessLocation, this.#beamThickness);

            const color = this.entryLocationColor;
            gl.uniform4f(this.#diffuseLocation, color.r, color.g, color.b, 0.9);

            this.drawLines(gl, this.#beamGeometry);

            break;
          }
        default:
          break;
      }
      gl.enable(gl.CULL_FACE);
    }

    drawLines(gl, geometry) {
      const buffer = this.#viewer.shaderCache.getBuffer(gl, geometry);
      gl.bindVertexArray(buffer.vertexArray);
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer.indexBuf);

      gl.drawElements(gl.TRIANGLES, geometry.count, gl.UNSIGNED_SHORT, 0);
    }
  }
