import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "authentication/Login";
import AuthWrapper from "./AuthWrapper";
import CheckingCredentials from "./CheckingCredentials";
import ChangePassword from "./ChangePassword";
import Reset from "authentication/Reset";

export default function HOCAuthenticator(ChildComponent) {
  return class extends React.Component {
    state = {
      isLoggedIn: false,
    };
    render() {
      if (this.state.isLoggedIn) {
        return <ChildComponent {...this.props} />;
      } else {
        return (
          <Routes>
            <Route path="/" element={<AuthWrapper />}>
              <Route
                index
                element={
                  <Login
                    setLoggedIn={(isLoggedIn) => this.setState({ isLoggedIn })}
                  />
                }
              />
              <Route path="reset" element={<Reset />} />
              <Route path="change" element={<ChangePassword />} />
              <Route
                path="*"
                element={
                  <CheckingCredentials
                    setLoggedIn={(isLoggedIn) => this.setState({ isLoggedIn })}
                  />
                }
              />
            </Route>
          </Routes>
        );
      }
    }
  };
}

export { HOCAuthenticator };
