import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { Loading } from "react/views";
import axios from "axios";
import { BASE_URI } from "config";
import { useSearchParams, useNavigate } from "react-router-dom";

interface IProps {
  setLoggedIn: Function;
  redirectLink?: string;
}
export default function CheckingCredentials({ setLoggedIn }: IProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let redirectLink = "";
    if (!searchParams.has("redirect")) {
      redirectLink = window.location.pathname + window.location.search.replaceAll('&', '$');
      searchParams.set(
        "redirect",
        redirectLink
      );
    } else {
      redirectLink = searchParams.get("redirect") as string;
    }

    axios
      .get(`${BASE_URI}/auth`, { withCredentials: true })
      .then((response) => {
        if (response.status === 200 && response.data.loggedIn) {
            if(!!!redirectLink) {
              navigate(redirectLink);
            }
            setLoggedIn(true);
        } else {
          setLoggedIn(false);
          let newSearch = "";
          if(!!redirectLink) {
            newSearch = `?redirect=${redirectLink}`;
          }
          navigate(`/${newSearch}`);
        }
      })
      .catch(() => {
        setLoggedIn(false);
        let newSearch = "";
        if (!!redirectLink) {
          newSearch = `?redirect=${redirectLink}`;
        }
        navigate(`/${newSearch}`);
      });
  }, []);
  return (
    <Stack spacing={2} pt={2}>
      <Loading size={40} relative />
      <Typography>Checking Credentials</Typography>
    </Stack>
  );
}
