export function getAPIEndpoint(): string {
  let apiEndpoint = "https://api.skyqraft.com";
  switch (window.location.origin) {
    case "https://lidar.skyqraft.com":
      apiEndpoint = "https://api.skyqraft.com";
      break;
    case "https://dev.lidar.skyqraft.com":
      apiEndpoint = "https://api.dev.skyqraft.com";
      break;
    case "https://lidar.hawkeye.skyqraft.com":
      apiEndpoint = "https://api.hawkeye.skyqraft.com";
      break;
    case "https://lidar.atom.skyqraft.com":
      apiEndpoint = "https://api.atom.skyqraft.com";
      break;
    case "https://lidar.us.skyqraft.com":
      apiEndpoint = "https://api.us.skyqraft.com";
      break;
    default:
      apiEndpoint = "http://localhost:8080";
  }
  return apiEndpoint;
}

export function getMapURL(): string {
  let apiEndpoint = "https://map.skyqraft.com";
  switch (window.location.origin) {
    case "https://lidar.skyqraft.com":
      apiEndpoint = "https://map.skyqraft.com";
      break;
    case "https://dev.lidar.skyqraft.com":
      apiEndpoint = "https://dev.skyqraft.com";
      break;
    case "https://lidar.hawkeye.skyqraft.com":
      apiEndpoint = "https://hawkeye.skyqraft.com";
      break;
    case "https://lidar.atom.skyqraft.com":
      apiEndpoint = "https://atom.skyqraft.com";
      break;
    case "https://lidar.us.skyqraft.com":
      apiEndpoint = "https://us.skyqraft.com";
      break;
  }
  return apiEndpoint;
}

export function getCognitoDetails() {
  switch (window.location.origin) {
    case "https://lidar.hawkeye.skyqraft.com":
      return {
        region: "eu-north-1",
        identityPoolID: "eu-north-1:d05f1dd2-d7cd-46ad-a5f3-c4dda1a39fc0",
        userPoolID: "eu-north-1_wepsrrkXZ",
        clientID: "6o9hi7av88pb0b6e3geukflv39"
      }
    default:
      return {
        region: "eu-central-1",
        identityPoolID: "eu-central-1:9d33aad4-9501-4119-ad09-7eba2dd9bfe9",
        userPoolID: "eu-central-1_RidTYUeyo",
        clientID: "70fu1djl46asc6vn8a8lnnbkg3"
      }
  }
}
