/*
*   Essentially a recreation of the backend rule engine but in JS.
*   :)))))))))))))))))
*   Was this a good idea?
*/

export function findUsedClasses(rules) {
    let classifications = new Set();

    for(const rule of rules["rules"]) {
        const conditions = rule["conditions"];

        for(const sub_condition of Object.values(conditions)) {
            for(const condition of sub_condition) {
                if (condition["name"] == "classification") {
                    classifications.add(rules["parameters"]["non_metric"][condition["value"]]);
                }
            }
        }
    }

    return classifications;
}

/// Evalutate the rules for a single grid point.
export function evaluateRules(rules, grid_x, grid_y, linePoints, classification, meters_per_grid_square) {
    //TODO: Move out one step for performance.
    const rule_parameters = {
    };

    if(!!rules["parameters"]["metric"]) {
        Object.entries(rules["parameters"]["metric"]).forEach(([key, value]) => {
            rule_parameters[key] = value / meters_per_grid_square;
        });
    }
    Object.entries(rules["parameters"]["non_metric"]).forEach(([key, value]) => {
        rule_parameters[key] = value;
    });

    const point_properties = {
        classification: classification,
        distance_from_phase: Infinity,
        distance_from_phase_horizontal: Infinity,
        distance_from_outer_phase_horizontal: Infinity,
        distance_from_phase_vertical: Infinity,
        // TODO: Some of these should be hoisted.
        distance_from_centre_line: Infinity,
        distance_from_centre_line_horizontal: Infinity,
        distance_from_centre_line_vertical: Infinity,
        height_of_closest_phase: Infinity,
        height_of_powerline: Infinity,
        position_along_span: 0,
    };

    for(const [l_x, l_y] of linePoints) {
        const x_dist = Math.abs(grid_x - l_x) * meters_per_grid_square;
        const y_dist = Math.abs(grid_y - l_y) * meters_per_grid_square;
        if(x_dist < point_properties["distance_from_phase_horizontal"]) {
            point_properties["distance_from_phase_horizontal"] = x_dist;
        }
        if (y_dist < point_properties["distance_from_centre_line_vertical"]) {
            point_properties["distance_from_centre_line_vertical"] = y_dist;
        }

        const distance = Math.sqrt(x_dist**2 + y_dist**2);
        if(distance < point_properties["distance_from_phase"]) {
            point_properties["distance_from_phase"] = distance;
        }
    }

    for(const rule of rules["rules"]) {
        const conditions = rule["conditions"];

        const passed_condition = evaluateConditions(conditions, point_properties, rule_parameters);
        if(passed_condition) {
            return rule["actions"];
        }
    }

    return [];
}

function evaluateConditions(conditions, point_properties, rule_parameters) {
    const require_all_conditions = !!conditions["all"];
    const sub_conditions = require_all_conditions ? conditions["all"] : conditions["any"];

    for(const condition of sub_conditions) {
        const name = point_properties[condition["name"]];
        const value = rule_parameters[condition["value"]];

        const condition_result = evaluateCondition(condition["operator"], name, value);
        if(!require_all_conditions && condition_result) {
            return true;
        } else if (require_all_conditions && !condition_result) {
            return false;
        }
    }

    // At this point:
    // 1. We passed all conditions & required all == Success.
    // 2. We passed no conditions & required any == Failure.
    return require_all_conditions;
}

function evaluateCondition(operator, value_a, value_b) {
    switch(operator) {
        case "less_than":
            return value_a < value_b;
        case "less_than_or_equal_to":
            return value_a <= value_b;
        case "equal_to":
            return value_a === value_b;
        case "greater_than":
            return value_a > value_b;
        case "greater_than_or_equal_to":
            return value_a >= value_b;
        default:
            return false;
    }
}