import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "react/views";
import { BASE_URI } from "config";
import { Check, Clear } from "@mui/icons-material";

export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [verifyPassword, setVerifyPassword] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageLevel, setMessageLevel] = useState<
    "info" | "warning" | "error"
  >("info");

  const session = searchParams.get("session");
  const email = searchParams.get("email")?.replaceAll(" ", "+");

  useEffect(() => {
    if (!!session && !!email) {
      setMessageLevel("info");
      setMessage("You must set a new password");
    } else {
      setMessage("You do not have a valid session");
      setMessageLevel("error");
    }
  }, []);

  function onPasswordChange(e) {
    e.preventDefault();
    setSending(true);
    axios
      .post(`${BASE_URI}/auth/new_password`, {
        password,
        session,
        email,
      })
      .then(() => {
        setSending(false);
        navigate("/?resetSuccess=true");
      })
      .catch((err) => {
        setSending(false);
        setMessage(err.response.data);
        setMessageLevel("error");
      });
  }

  const passwordStatus = {
    length: password.length >= 8,
    number: /[0-9]/.test(password),
    letters: /[a-z]/.test(password) && /[A-Z]/.test(password),
    characters: /[@$!%*#?^+-]/.test(password),
  };
  const passwordValid =
    passwordStatus.length &&
    passwordStatus.number &&
    passwordStatus.letters &&
    passwordStatus.characters;
  const passwordsIdentical = password === verifyPassword;
  return (
    <>
      {!!message && (
        <Alert
          severity={messageLevel}
          sx={{ mb: 1 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      <Typography component="h1" variant="h5">
        Change Password
      </Typography>
      <Typography textAlign="center" color="text.secondary">
        {email}
      </Typography>

      <Box
        component="form"
        onSubmit={onPasswordChange}
        noValidate
        autoComplete="off"
        sx={{ mt: 1, width: "100%" }}
      >
        <Typography>Enter your new password</Typography>
        <Tooltip
          placement="right"
          arrow
          disableInteractive
          open={true}
          title={
            <Stack spacing={1}>
              <Typography fontSize="small">The password should have</Typography>
              <Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  {passwordStatus.length ? (
                    <Check htmlColor="green" fontSize="small" />
                  ) : (
                    <Clear htmlColor="red" fontSize="small" />
                  )}
                  <Typography fontSize="small">8 or more characters</Typography>
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  {passwordStatus.letters ? (
                    <Check htmlColor="green" fontSize="small" />
                  ) : (
                    <Clear htmlColor="red" fontSize="small" />
                  )}
                  <Typography fontSize="small">
                    A small and large letter
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  {passwordStatus.number ? (
                    <Check htmlColor="green" fontSize="small" />
                  ) : (
                    <Clear htmlColor="red" fontSize="small" />
                  )}
                  <Typography fontSize="small">A number</Typography>
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  {passwordStatus.characters ? (
                    <Check htmlColor="green" fontSize="small" />
                  ) : (
                    <Clear htmlColor="red" fontSize="small" />
                  )}
                  <Typography fontSize="small">
                    A special character (@$!%*#?&*.,+-_)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          }
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="New password"
            name="password"
            type="password"
            autoComplete="password"
            value={password}
            error={password.length > 0 && !passwordValid}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Tooltip>
        <TextField
          sx={{ mt: 0 }}
          margin="normal"
          required
          fullWidth
          id="password_verify"
          type="password"
          label="Verify new password"
          name="password_verify"
          autoComplete="off"
          error={
            verifyPassword.length > 0 && (!passwordsIdentical || !passwordValid)
          }
          value={verifyPassword}
          onChange={(e) => setVerifyPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          endIcon={sending && <Loading relative size={20} />}
          disabled={!passwordValid || !passwordsIdentical}
          sx={{ mb: 2 }}
        >
          Set password
        </Button>
      </Box>
      <Link to="/" style={{ color: "#0078EB" }}>
        Go back to login
      </Link>
    </>
  );
}
