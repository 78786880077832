import { EventDispatcher } from "../events.js";
import { Vector2, Vector3 } from "../mathtypes.js";
import * as MathUtils from "../utils/math.js";
import { AddEquation, AlwaysStencilFunc, BasicDepthPacking, FrontSide, KeepStencilOp, LessEqualDepth, MultiplyOperation, NormalBlending, OneMinusSrcAlphaFactor, SrcAlphaFactor, TangentSpaceNormalMap } from "./constants";
import { cloneUniforms } from "./shaders.js";
import * as Shaders from "./shaders.js";
import { Color } from "./types.js";

let materialId = 0;

export class Material extends EventDispatcher{
    isMaterial = true;
    type = "Material";

    blending = NormalBlending;
    side = FrontSide;
    flatShading = false;
    vertexColors = false;

    opacity = 1;
    transparent = false;

    blendSrc = SrcAlphaFactor;
    blendDst = OneMinusSrcAlphaFactor;
    blendEquation = AddEquation;
    blendSrcAlpha = null;
    blendDstAlpha = null;
    blendEquationAlpha = null;

    depthFunc = LessEqualDepth;
    depthTest = true;
    depthWrite = true;

    stencilWriteMask = 0xff;
    stencilFunc = AlwaysStencilFunc;
    stencilRef = 0;
    stencilFuncMask = 0xff;
    stencilFail = KeepStencilOp;
    stencilZFail = KeepStencilOp;
    stencilZPass = KeepStencilOp;
    stencilWrite = false;

    clippingPlanes = null;
    clipIntersection = false;
    clipShadows = false;

    shadowSide = null;

    colorWrite = true;

    precision = null; // override the renderer's default precision for this material

    polygonOffset = false;
    polygonOffsetFactor = 0;
    polygonOffsetUnits = 0;

    dithering = false;

    alphaTest = 0;
    premultipliedAlpha = false;

    visible = true;

    toneMapped = true;
    version = 0;

    constructor() {
        super();

        Object.defineProperty(this, "id", { value: materialId++ });
        this.uuid = MathUtils.generateUUID();
        this.name = "";
    }

    onBeforeCompile() {}

    customProgramCacheKey() {
        return this.onBeforeCompile.toString();
    }

    setValues(values) {
        if (values === undefined) return;

        for (const key in values) {
          const newValue = values[key];

          if (newValue === undefined) {
            console.warn(`THREE.Material: '${key}' parameter is undefined.`);
            continue;
          }

          const currentValue = this[key];

          if (currentValue === undefined) {
            console.warn(`THREE.${this.type}: '${key}' is not a property of this material.`);
            continue;
          }

          if (currentValue?.isColor) {
            currentValue.set(newValue);
          } else if (
            currentValue?.isVector3 &&
            newValue &&
            newValue.isVector3
          ) {
            currentValue.copy(newValue);
          } else {
            this[key] = newValue;
          }
        }
    }

    clone() {
        return new this.constructor().copy(this);
    }

    copy(source) {
        this.name = source.name;

        this.blending = source.blending;
        this.side = source.side;
        this.flatShading = source.flatShading;
        this.vertexColors = source.vertexColors;

        this.opacity = source.opacity;
        this.transparent = source.transparent;

        this.blendSrc = source.blendSrc;
        this.blendDst = source.blendDst;
        this.blendEquation = source.blendEquation;
        this.blendSrcAlpha = source.blendSrcAlpha;
        this.blendDstAlpha = source.blendDstAlpha;
        this.blendEquationAlpha = source.blendEquationAlpha;

        this.depthFunc = source.depthFunc;
        this.depthTest = source.depthTest;
        this.depthWrite = source.depthWrite;

        this.stencilWriteMask = source.stencilWriteMask;
        this.stencilFunc = source.stencilFunc;
        this.stencilRef = source.stencilRef;
        this.stencilFuncMask = source.stencilFuncMask;
        this.stencilFail = source.stencilFail;
        this.stencilZFail = source.stencilZFail;
        this.stencilZPass = source.stencilZPass;
        this.stencilWrite = source.stencilWrite;

        const srcPlanes = source.clippingPlanes;
        let dstPlanes = null;

        if (srcPlanes !== null) {
          const n = srcPlanes.length;
          dstPlanes = new Array(n);

          for (let i = 0; i !== n; ++i) {
            dstPlanes[i] = srcPlanes[i].clone();
          }
        }

        this.clippingPlanes = dstPlanes;
        this.clipIntersection = source.clipIntersection;
        this.clipShadows = source.clipShadows;

        this.shadowSide = source.shadowSide;

        this.colorWrite = source.colorWrite;

        this.precision = source.precision;

        this.polygonOffset = source.polygonOffset;
        this.polygonOffsetFactor = source.polygonOffsetFactor;
        this.polygonOffsetUnits = source.polygonOffsetUnits;

        this.dithering = source.dithering;

        this.alphaTest = source.alphaTest;
        this.premultipliedAlpha = source.premultipliedAlpha;

        this.visible = source.visible;

        this.toneMapped = source.toneMapped;

        return this;
    }

    dispose() {
        this.dispatchEvent({ type: "dispose" });
    }
}

  Object.defineProperty(Material.prototype, "needsUpdate", {
    set: function (value) {
      if (value === true) this.version++;
    },
  });


  // Legacy
  const default_vertex =
    "void main() {\n\tgl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n}";

  const default_fragment =
    "void main() {\n\tgl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );\n}";

  /**
   * parameters = {
   *  defines: { "label" : "value" },
   *  uniforms: { "parameter1": { value: 1.0 }, "parameter2": { value2: 2 } },
   *
   *  fragmentShader: <string>,
   *  vertexShader: <string>,
   *
   *  wireframe: <boolean>,
   *  wireframeLinewidth: <float>,
   *
   *  lights: <bool>,
   *
   * }
   */

export class ShaderMaterial extends Material {
  isShaderMaterial = true;

  constructor(parameters) {
    super();

    this.type = "ShaderMaterial";

    this.defines = {};
    this.uniforms = {};

    this.vertexShader = default_vertex;
    this.fragmentShader = default_fragment;

    this.linewidth = 1;

    this.wireframe = false;
    this.wireframeLinewidth = 1;

    this.lights = false; // set to use scene lights
    this.clipping = false; // set to use user-defined clipping planes

    this.extensions = {
      derivatives: false,
      fragDepth: false,
      drawBuffers: false,
      shaderTextureLOD: false, // set to use shader texture LOD
    };

    // When rendered geometry doesn't include these attributes but the material does,
    // use these default values in WebGL. This avoids errors when buffer data is missing.
    this.defaultAttributeValues = {
      color: [1, 1, 1],
      uv: [0, 0],
      uv2: [0, 0],
    };

    this.index0AttributeName = undefined;
    this.uniformsNeedUpdate = false;

    this.glslVersion = null;

    if (parameters !== undefined) {
      if (parameters.attributes !== undefined) {
        console.error(
          "THREE.ShaderMaterial: attributes should now be defined in THREE.BufferGeometry instead."
        );
      }

      this.setValues(parameters);
    }
  }
  copy(source) {
    Material.prototype.copy.call(this, source);

    this.fragmentShader = source.fragmentShader;
    this.vertexShader = source.vertexShader;

    this.uniforms = cloneUniforms(source.uniforms);

    this.defines = { ...source.defines };

    this.wireframe = source.wireframe;
    this.wireframeLinewidth = source.wireframeLinewidth;

    this.lights = source.lights;
    this.clipping = source.clipping;

    this.extensions = { ...source.extensions };

    this.glslVersion = source.glslVersion;

    return this;
  }
}

/**
 * parameters = {
*  color: <hex>,
*  opacity: <float>,
*  map: new THREE.Texture( <Image> ),
*
*  lightMap: new THREE.Texture( <Image> ),
*  lightMapIntensity: <float>
*
*  aoMap: new THREE.Texture( <Image> ),
*  aoMapIntensity: <float>
*
*  specularMap: new THREE.Texture( <Image> ),
*
*  alphaMap: new THREE.Texture( <Image> ),
*
*  envMap: new THREE.CubeTexture( [posx, negx, posy, negy, posz, negz] ),
*  combine: THREE.Multiply,
*  reflectivity: <float>,
*  refractionRatio: <float>,
*
*  depthTest: <bool>,
*  depthWrite: <bool>,
*
*  wireframe: <boolean>,
*  wireframeLinewidth: <float>,
*
* }
*/
export class MeshBasicMaterial extends Material {
    constructor(parameters) {
      super();

      this.type = "MeshBasicMaterial";
      this.isMeshBasicMaterial = true;

      this.color = new Color(0xffffff); // emissive

      this.map = null;

      this.lightMap = null;
      this.lightMapIntensity = 1.0;

      this.aoMap = null;
      this.aoMapIntensity = 1.0;

      this.specularMap = null;

      this.alphaMap = null;

      this.envMap = null;
      this.combine = MultiplyOperation;
      this.reflectivity = 1;
      this.refractionRatio = 0.98;

      this.wireframe = false;
      this.wireframeLinewidth = 1;
      this.wireframeLinecap = "round";
      this.wireframeLinejoin = "round";

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.color.copy(source.color);

      this.map = source.map;

      this.lightMap = source.lightMap;
      this.lightMapIntensity = source.lightMapIntensity;

      this.aoMap = source.aoMap;
      this.aoMapIntensity = source.aoMapIntensity;

      this.specularMap = source.specularMap;

      this.alphaMap = source.alphaMap;

      this.envMap = source.envMap;
      this.combine = source.combine;
      this.reflectivity = source.reflectivity;
      this.refractionRatio = source.refractionRatio;

      this.wireframe = source.wireframe;
      this.wireframeLinewidth = source.wireframeLinewidth;
      this.wireframeLinecap = source.wireframeLinecap;
      this.wireframeLinejoin = source.wireframeLinejoin;

      return this;
    }
}

/**
 * parameters = {
*
*  opacity: <float>,
*
*  map: new THREE.Texture( <Image> ),
*
*  alphaMap: new THREE.Texture( <Image> ),
*
*  displacementMap: new THREE.Texture( <Image> ),
*  displacementScale: <float>,
*  displacementBias: <float>,
*
*  wireframe: <boolean>,
*  wireframeLinewidth: <float>
* }
*/
export class MeshDepthMaterial extends Material {
    isMeshDepthMaterial = true;

    constructor(parameters) {
      super();

      this.type = "MeshDepthMaterial";

      this.depthPacking = BasicDepthPacking;

      this.map = null;

      this.alphaMap = null;

      this.displacementMap = null;
      this.displacementScale = 1;
      this.displacementBias = 0;

      this.wireframe = false;
      this.wireframeLinewidth = 1;

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.depthPacking = source.depthPacking;

      this.map = source.map;

      this.alphaMap = source.alphaMap;

      this.displacementMap = source.displacementMap;
      this.displacementScale = source.displacementScale;
      this.displacementBias = source.displacementBias;

      this.wireframe = source.wireframe;
      this.wireframeLinewidth = source.wireframeLinewidth;

      return this;
    }
}

/**
 * parameters = {
*
*  referencePosition: <float>,
*  nearDistance: <float>,
*  farDistance: <float>,
*
*
*  map: new THREE.Texture( <Image> ),
*
*  alphaMap: new THREE.Texture( <Image> ),
*
*  displacementMap: new THREE.Texture( <Image> ),
*  displacementScale: <float>,
*  displacementBias: <float>
*
* }
*/
export class MeshDistanceMaterial extends Material {
    isMeshDistanceMaterial = true;

    constructor(parameters) {
      super();

      this.type = "MeshDistanceMaterial";

      this.referencePosition = new Vector3();
      this.nearDistance = 1;
      this.farDistance = 1000;

      this.map = null;

      this.alphaMap = null;

      this.displacementMap = null;
      this.displacementScale = 1;
      this.displacementBias = 0;

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.referencePosition.copy(source.referencePosition);
      this.nearDistance = source.nearDistance;
      this.farDistance = source.farDistance;

      this.map = source.map;

      this.alphaMap = source.alphaMap;

      this.displacementMap = source.displacementMap;
      this.displacementScale = source.displacementScale;
      this.displacementBias = source.displacementBias;

      return this;
    }
}

  /**
   * parameters = {
   *  color: <hex>,
   *  map: new THREE.Texture( <Image> ),
   *  alphaMap: new THREE.Texture( <Image> ),
   *  rotation: <float>,
   *  sizeAttenuation: <bool>
   * }
   */
export class SpriteMaterial extends Material {
    constructor(parameters) {
      super();

      this.type = "SpriteMaterial";
      this.isSpriteMaterial = true;

      this.color = new Color(0xffffff);

      this.map = null;

      this.alphaMap = null;

      this.rotation = 0;

      this.sizeAttenuation = true;

      this.transparent = true;

      this.setValues(parameters);
    }

    copy(source) {
      Material.prototype.copy.call(this, source);

      this.color.copy(source.color);

      this.map = source.map;

      this.alphaMap = source.alphaMap;

      this.rotation = source.rotation;

      this.sizeAttenuation = source.sizeAttenuation;

      return this;
    }
}


/**
 * parameters = {
*  color: <hex>,
*  opacity: <float>,
*
*  linewidth: <float>,
*  linecap: "round",
*  linejoin: "round"
* }
*/
export class LineBasicMaterial extends Material {
    isLineBasicMaterial = true;

    constructor(parameters) {
      super();

      this.type = "LineBasicMaterial";

      this.color = new Color(0xffffff);

      this.linewidth = 1;
      this.linecap = "round";
      this.linejoin = "round";

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.color.copy(source.color);

      this.linewidth = source.linewidth;
      this.linecap = source.linecap;
      this.linejoin = source.linejoin;

      return this;
    }
}


/**
 * parameters = {
*  color: <hex>,
*  opacity: <float>,
*  map: new THREE.Texture( <Image> ),
*  alphaMap: new THREE.Texture( <Image> ),
*
*  size: <float>,
*  sizeAttenuation: <bool>
*
* }
*/
export class PointsMaterial extends Material {
    isPointsMaterial = true;

    constructor(parameters) {
      super();

      this.type = "PointsMaterial";

      this.color = new Color(0xffffff);

      this.map = null;

      this.alphaMap = null;

      this.size = 1;
      this.sizeAttenuation = true;

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.color.copy(source.color);

      this.map = source.map;

      this.alphaMap = source.alphaMap;

      this.size = source.size;
      this.sizeAttenuation = source.sizeAttenuation;

      return this;
    }
}

/**
 * parameters = {
*  color: <hex>,
*  roughness: <float>,
*  metalness: <float>,
*  opacity: <float>,
*
*  map: new THREE.Texture( <Image> ),
*
*  lightMap: new THREE.Texture( <Image> ),
*  lightMapIntensity: <float>
*
*  aoMap: new THREE.Texture( <Image> ),
*  aoMapIntensity: <float>
*
*  emissive: <hex>,
*  emissiveIntensity: <float>
*  emissiveMap: new THREE.Texture( <Image> ),
*
*  bumpMap: new THREE.Texture( <Image> ),
*  bumpScale: <float>,
*
*  normalMap: new THREE.Texture( <Image> ),
*  normalMapType: THREE.TangentSpaceNormalMap,
*  normalScale: <Vector2>,
*
*  displacementMap: new THREE.Texture( <Image> ),
*  displacementScale: <float>,
*  displacementBias: <float>,
*
*  roughnessMap: new THREE.Texture( <Image> ),
*
*  metalnessMap: new THREE.Texture( <Image> ),
*
*  alphaMap: new THREE.Texture( <Image> ),
*
*  envMap: new THREE.CubeTexture( [posx, negx, posy, negy, posz, negz] ),
*  envMapIntensity: <float>
*
*  refractionRatio: <float>,
*
*  wireframe: <boolean>,
*  wireframeLinewidth: <float>,
   *
* }
*/
export class MeshStandardMaterial extends Material {
    isMeshStandardMaterial = true;

    constructor(parameters) {
      super();

      this.defines = { STANDARD: "" };

      this.type = "MeshStandardMaterial";

      this.color = new Color(0xffffff); // diffuse
      this.roughness = 1.0;
      this.metalness = 0.0;

      this.map = null;

      this.lightMap = null;
      this.lightMapIntensity = 1.0;

      this.aoMap = null;
      this.aoMapIntensity = 1.0;

      this.emissive = new Color(0x000000);
      this.emissiveIntensity = 1.0;
      this.emissiveMap = null;

      this.bumpMap = null;
      this.bumpScale = 1;

      this.normalMap = null;
      this.normalMapType = TangentSpaceNormalMap;
      this.normalScale = new Vector2(1, 1);

      this.displacementMap = null;
      this.displacementScale = 1;
      this.displacementBias = 0;

      this.roughnessMap = null;

      this.metalnessMap = null;

      this.alphaMap = null;

      this.envMap = null;
      this.envMapIntensity = 1.0;

      this.refractionRatio = 0.98;

      this.wireframe = false;
      this.wireframeLinewidth = 1;
      this.wireframeLinecap = "round";
      this.wireframeLinejoin = "round";

      this.vertexTangents = false;

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.defines = { STANDARD: "" };

      this.color.copy(source.color);
      this.roughness = source.roughness;
      this.metalness = source.metalness;

      this.map = source.map;

      this.lightMap = source.lightMap;
      this.lightMapIntensity = source.lightMapIntensity;

      this.aoMap = source.aoMap;
      this.aoMapIntensity = source.aoMapIntensity;

      this.emissive.copy(source.emissive);
      this.emissiveMap = source.emissiveMap;
      this.emissiveIntensity = source.emissiveIntensity;

      this.bumpMap = source.bumpMap;
      this.bumpScale = source.bumpScale;

      this.normalMap = source.normalMap;
      this.normalMapType = source.normalMapType;
      this.normalScale.copy(source.normalScale);

      this.displacementMap = source.displacementMap;
      this.displacementScale = source.displacementScale;
      this.displacementBias = source.displacementBias;

      this.roughnessMap = source.roughnessMap;

      this.metalnessMap = source.metalnessMap;

      this.alphaMap = source.alphaMap;

      this.envMap = source.envMap;
      this.envMapIntensity = source.envMapIntensity;

      this.refractionRatio = source.refractionRatio;

      this.wireframe = source.wireframe;
      this.wireframeLinewidth = source.wireframeLinewidth;
      this.wireframeLinecap = source.wireframeLinecap;
      this.wireframeLinejoin = source.wireframeLinejoin;

      this.vertexTangents = source.vertexTangents;

      return this;
    }
}

/**
 * parameters = {
*  opacity: <float>,
*
*  bumpMap: new THREE.Texture( <Image> ),
*  bumpScale: <float>,
*
*  normalMap: new THREE.Texture( <Image> ),
*  normalMapType: THREE.TangentSpaceNormalMap,
*  normalScale: <Vector2>,
*
*  displacementMap: new THREE.Texture( <Image> ),
*  displacementScale: <float>,
*  displacementBias: <float>,
*
*  wireframe: <boolean>,
*  wireframeLinewidth: <float>
*
* }
*/
export class MeshNormalMaterial extends Material {
    isMeshNormalMaterial = true;

    constructor(parameters) {
      super();

      this.type = "MeshNormalMaterial";

      this.bumpMap = null;
      this.bumpScale = 1;

      this.normalMap = null;
      this.normalMapType = TangentSpaceNormalMap;
      this.normalScale = new Vector2(1, 1);

      this.displacementMap = null;
      this.displacementScale = 1;
      this.displacementBias = 0;

      this.wireframe = false;
      this.wireframeLinewidth = 1;

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.bumpMap = source.bumpMap;
      this.bumpScale = source.bumpScale;

      this.normalMap = source.normalMap;
      this.normalMapType = source.normalMapType;
      this.normalScale.copy(source.normalScale);

      this.displacementMap = source.displacementMap;
      this.displacementScale = source.displacementScale;
      this.displacementBias = source.displacementBias;

      this.wireframe = source.wireframe;
      this.wireframeLinewidth = source.wireframeLinewidth;

      return this;
    }
}
/**
* parameters = {
*  color: <hex>,
*  opacity: <float>,
*
*  map: new THREE.Texture( <Image> ),
*
*  lightMap: new THREE.Texture( <Image> ),
*  lightMapIntensity: <float>
*
*  aoMap: new THREE.Texture( <Image> ),
*  aoMapIntensity: <float>
*
*  emissive: <hex>,
*  emissiveIntensity: <float>
*  emissiveMap: new THREE.Texture( <Image> ),
*
*  specularMap: new THREE.Texture( <Image> ),
*
*  alphaMap: new THREE.Texture( <Image> ),
*
*  envMap: new THREE.CubeTexture( [posx, negx, posy, negy, posz, negz] ),
*  combine: THREE.Multiply,
*  reflectivity: <float>,
*  refractionRatio: <float>,
*
*  wireframe: <boolean>,
*  wireframeLinewidth: <float>,
*
* }
*/
export class MeshLambertMaterial extends Material {
    isMeshLambertMaterial = true;

    constructor(parameters) {
      super();

      this.type = "MeshLambertMaterial";

      this.color = new Color(0xffffff); // diffuse

      this.map = null;

      this.lightMap = null;
      this.lightMapIntensity = 1.0;

      this.aoMap = null;
      this.aoMapIntensity = 1.0;

      this.emissive = new Color(0x000000);
      this.emissiveIntensity = 1.0;
      this.emissiveMap = null;

      this.specularMap = null;

      this.alphaMap = null;

      this.envMap = null;
      this.combine = MultiplyOperation;
      this.reflectivity = 1;
      this.refractionRatio = 0.98;

      this.wireframe = false;
      this.wireframeLinewidth = 1;
      this.wireframeLinecap = "round";
      this.wireframeLinejoin = "round";

      this.setValues(parameters);
    }
    copy(source) {
      Material.prototype.copy.call(this, source);

      this.color.copy(source.color);

      this.map = source.map;

      this.lightMap = source.lightMap;
      this.lightMapIntensity = source.lightMapIntensity;

      this.aoMap = source.aoMap;
      this.aoMapIntensity = source.aoMapIntensity;

      this.emissive.copy(source.emissive);
      this.emissiveMap = source.emissiveMap;
      this.emissiveIntensity = source.emissiveIntensity;

      this.specularMap = source.specularMap;

      this.alphaMap = source.alphaMap;

      this.envMap = source.envMap;
      this.combine = source.combine;
      this.reflectivity = source.reflectivity;
      this.refractionRatio = source.refractionRatio;

      this.wireframe = source.wireframe;
      this.wireframeLinewidth = source.wireframeLinewidth;
      this.wireframeLinecap = source.wireframeLinecap;
      this.wireframeLinejoin = source.wireframeLinejoin;

      return this;
    }
}

export class RawShaderMaterial extends ShaderMaterial {
  isRawShaderMaterial = true;

  constructor() {
    super();

    this.type = "RawShaderMaterial";
  }
}

  //
  // Algorithm by Christian Boucheny
  // shader code taken and adapted from CloudCompare
  //
  // see
  // https://github.com/cloudcompare/trunk/tree/master/plugins/qEDL/shaders/EDL
  // http://www.kitware.com/source/home/post/9
  // https://tel.archives-ouvertes.fr/tel-00438464/document p. 115+ (french)

export class EyeDomeLightingMaterial extends RawShaderMaterial {
    constructor() {
      super();

      this.type = "EyeDomeLightingMaterial";

      const uniforms = {
        screenWidth: { type: "f", value: 0 },
        screenHeight: { type: "f", value: 0 },
        edlStrength: { type: "f", value: 1.0 },
        uNear: { type: "f", value: 1.0 },
        uFar: { type: "f", value: 1.0 },
        radius: { type: "f", value: 1.0 },
        neighbours: { type: "2fv", value: [] },
        depthMap: { type: "t", value: null },
        uEDLColor: { type: "t", value: null },
        uEDLDepth: { type: "t", value: null },
        opacity: { type: "f", value: 1.0 },
        uProj: { type: "Matrix4fv", value: [] },
      };

      this.setValues({
        uniforms: uniforms,
        vertexShader: this.getDefines() + Shaders.get("edl.vert"),
        fragmentShader: this.getDefines() + Shaders.get("edl.frag"),
        lights: false,
      });


      this.neighbourCount = 8;
    }

    getDefines() {
      let defines = "";

      defines += `#define NEIGHBOUR_COUNT ${this.neighbourCount}\n`;

      return defines;
    }

    updateShaderSource() {
      const vs = this.getDefines() + Shaders.get("edl.vert");
      const fs = this.getDefines() + Shaders.get("edl.frag");

      this.setValues({
        vertexShader: vs,
        fragmentShader: fs,
      });

      this.uniforms.neighbours.value = this.neighbours;

      this.needsUpdate = true;
    }

    get neighbourCount() {
      return this._neighbourCount;
    }

    set neighbourCount(value) {
      if (this._neighbourCount !== value) {
        this._neighbourCount = value;
        this.neighbours = new Float32Array(this._neighbourCount * 2);
        for (let c = 0; c < this._neighbourCount; c++) {
          this.neighbours[2 * c + 0] = Math.cos(
            (2 * c * Math.PI) / this._neighbourCount
          );
          this.neighbours[2 * c + 1] = Math.sin(
            (2 * c * Math.PI) / this._neighbourCount
          );
        }

        this.updateShaderSource();
      }
    }
  }