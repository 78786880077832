import { EventDispatcher } from "./events";
import { Box3, Matrix3, Matrix4, Ray, Sphere, Vector2, Vector3 } from "./mathtypes";
import { Object3D } from "./object3d";
import { BufferAttribute, Float32BufferAttribute, Uint16BufferAttribute, Uint32BufferAttribute } from "./rendering/bufferattribute";
import { BackSide, DoubleSide } from "./rendering/constants";
import { MeshBasicMaterial } from "./rendering/material";
import { Color } from "./rendering/types";
import { arrayMax, generateUUID } from "./utils/math";

class DirectGeometry {
    constructor() {
      this.vertices = [];
      this.normals = [];
      this.colors = [];
      this.uvs = [];
      this.uvs2 = [];

      this.groups = [];

      this.boundingBox = null;
      this.boundingSphere = null;

      // update flags

      this.verticesNeedUpdate = false;
      this.normalsNeedUpdate = false;
      this.colorsNeedUpdate = false;
      this.uvsNeedUpdate = false;
      this.groupsNeedUpdate = false;
    }

    computeGroups(geometry) {
      const groups = [];

      let group, i;
      let materialIndex = undefined;

      const faces = geometry.faces;

      for (i = 0; i < faces.length; i++) {
        const face = faces[i];

        // materials
        if (face.materialIndex !== materialIndex) {
          materialIndex = face.materialIndex;

          if (group !== undefined) {
            group.count = i * 3 - group.start;
            groups.push(group);
          }

          group = {
            start: i * 3,
            materialIndex: materialIndex,
          };
        }
      }

      if (group !== undefined) {
        group.count = i * 3 - group.start;
        groups.push(group);
      }

      this.groups = groups;
    }

    fromGeometry(geometry) {
      const faces = geometry.faces;
      const vertices = geometry.vertices;
      const faceVertexUvs = geometry.faceVertexUvs;

      const hasFaceVertexUv = faceVertexUvs[0] && faceVertexUvs[0].length > 0;
      const hasFaceVertexUv2 = faceVertexUvs[1] && faceVertexUvs[1].length > 0;

      //

      if (vertices.length > 0 && faces.length === 0) {
        console.error("THREE.DirectGeometry: Faceless geometries are not supported.");
      }

      for (let i = 0; i < faces.length; i++) {
        const face = faces[i];

        this.vertices.push(
          vertices[face.a],
          vertices[face.b],
          vertices[face.c]
        );

        const vertexNormals = face.vertexNormals;

        if (vertexNormals.length === 3) {
          this.normals.push(
            vertexNormals[0],
            vertexNormals[1],
            vertexNormals[2]
          );
        } else {
          const normal = face.normal;

          this.normals.push(normal, normal, normal);
        }

        const vertexColors = face.vertexColors;

        if (vertexColors.length === 3) {
          this.colors.push(vertexColors[0], vertexColors[1], vertexColors[2]);
        } else {
          const color = face.color;

          this.colors.push(color, color, color);
        }

        if (hasFaceVertexUv === true) {
          const vertexUvs = faceVertexUvs[0][i];

          if (vertexUvs !== undefined) {
            this.uvs.push(vertexUvs[0], vertexUvs[1], vertexUvs[2]);
          } else {
            console.warn("THREE.DirectGeometry.fromGeometry(): Undefined vertexUv ", i);

            this.uvs.push(new Vector2(), new Vector2(), new Vector2());
          }
        }

        if (hasFaceVertexUv2 === true) {
          const vertexUvs = faceVertexUvs[1][i];

          if (vertexUvs !== undefined) {
            this.uvs2.push(vertexUvs[0], vertexUvs[1], vertexUvs[2]);
          } else {
            console.warn("THREE.DirectGeometry.fromGeometry(): Undefined vertexUv2 ", i);

            this.uvs2.push(new Vector2(), new Vector2(), new Vector2());
          }
        }
      }

      this.computeGroups(geometry);

      this.verticesNeedUpdate = geometry.verticesNeedUpdate;
      this.normalsNeedUpdate = geometry.normalsNeedUpdate;
      this.colorsNeedUpdate = geometry.colorsNeedUpdate;
      this.uvsNeedUpdate = geometry.uvsNeedUpdate;
      this.groupsNeedUpdate = geometry.groupsNeedUpdate;

      if (geometry.boundingSphere !== null) {
        this.boundingSphere = geometry.boundingSphere.clone();
      }

      if (geometry.boundingBox !== null) {
        this.boundingBox = geometry.boundingBox.clone();
      }

      return this;
    }
  }

let _geometryId = 0; // Geometry uses even numbers as Id
let _bufferGeometryId = 1; // BufferGeometry uses odd numbers as Id

const _m1$3 = new Matrix4();
const _offset = new Vector3();
const _box$2 = new Box3();
const _vector$4 = new Vector3();

export class BufferGeometry extends EventDispatcher {
    isBufferGeometry = true;
    constructor() {
      super();
        Object.defineProperty(this, "id", { value: (_bufferGeometryId += 2) });

        this.uuid = generateUUID();

        this.name = "";
        this.type = "BufferGeometry";

        this.index = null;
        this.attributes = {};

        this.groups = [];

        this.boundingBox = null;
        this.boundingSphere = null;

        this.drawRange = { start: 0, count: Infinity };

        this.userData = {};
    }

    getIndex() {
        return this.index;
      }

      setIndex(index) {
        if (Array.isArray(index)) {
          this.index = new (
            arrayMax(index) > 65535
              ? Uint32BufferAttribute
              : Uint16BufferAttribute
          )(index, 1);
        } else {
          this.index = index;
        }

        return this;
      }

      getAttribute(name) {
        return this.attributes[name];
      }

      setAttribute(name, attribute) {
        this.attributes[name] = attribute;

        return this;
      }

      deleteAttribute(name) {
        delete this.attributes[name];

        return this;
      }

      hasAttribute(name) {
        return this.attributes[name] !== undefined;
      }

      addGroup(start, count, materialIndex = 0) {
        this.groups.push({
          start: start,
          count: count,
          materialIndex: materialIndex,
        });
      }

      clearGroups() {
        this.groups = [];
      }

      setDrawRange(start, count) {
        this.drawRange.start = start;
        this.drawRange.count = count;
      }

      applyMatrix4(matrix) {
        const position = this.attributes.position;

        if (position !== undefined) {
          position.applyMatrix4(matrix);

          position.needsUpdate = true;
        }

        const normal = this.attributes.normal;

        if (normal !== undefined) {
          const normalMatrix = new Matrix3().getNormalMatrix(matrix);

          normal.applyNormalMatrix(normalMatrix);

          normal.needsUpdate = true;
        }

        const tangent = this.attributes.tangent;

        if (tangent !== undefined) {
          tangent.transformDirection(matrix);

          tangent.needsUpdate = true;
        }

        if (this.boundingBox !== null) {
          this.computeBoundingBox();
        }

        if (this.boundingSphere !== null) {
          this.computeBoundingSphere();
        }

        return this;
      }

      rotateX(angle) {
        // rotate geometry around world x-axis
        _m1$3.makeRotationX(angle);
        this.applyMatrix4(_m1$3);

        return this;
      }

      rotateY(angle) {
        // rotate geometry around world y-axis
        _m1$3.makeRotationY(angle);
        this.applyMatrix4(_m1$3);

        return this;
      }

      rotateZ(angle) {
        // rotate geometry around world z-axis
        _m1$3.makeRotationZ(angle);
        this.applyMatrix4(_m1$3);

        return this;
      }

      translate(x, y, z) {
        // translate geometry
        _m1$3.makeTranslation(x, y, z);
        this.applyMatrix4(_m1$3);

        return this;
      }

      scale(x, y, z) {
        // scale geometry
        _m1$3.makeScale(x, y, z);
        this.applyMatrix4(_m1$3);

        return this;
      }

      center() {
        this.computeBoundingBox();

        this.boundingBox.getCenter(_offset).negate();

        this.translate(_offset.x, _offset.y, _offset.z);

        return this;
      }

      setFromObject(object) {
        const geometry = object.geometry;

        if (object.isPoints || object.isLine) {
          const positions = new Float32BufferAttribute(
            geometry.vertices.length * 3,
            3
          );
          const colors = new Float32BufferAttribute(
            geometry.colors.length * 3,
            3
          );

          this.setAttribute(
            "position",
            positions.copyVector3sArray(geometry.vertices)
          );
          this.setAttribute("color", colors.copyColorsArray(geometry.colors));

          if (
            geometry.lineDistances &&
            geometry.lineDistances.length === geometry.vertices.length
          ) {
            const lineDistances = new Float32BufferAttribute(
              geometry.lineDistances.length,
              1
            );

            this.setAttribute(
              "lineDistance",
              lineDistances.copyArray(geometry.lineDistances)
            );
          }

          if (geometry.boundingSphere !== null) {
            this.boundingSphere = geometry.boundingSphere.clone();
          }

          if (geometry.boundingBox !== null) {
            this.boundingBox = geometry.boundingBox.clone();
          }
        } else if (object.isMesh) {
          if (geometry?.isGeometry) {
            this.fromGeometry(geometry);
          }
        }

        return this;
      }

      setFromPoints(points) {
        const position = [];

        for (let i = 0, l = points.length; i < l; i++) {
          const point = points[i];
          position.push(point.x, point.y, point.z || 0);
        }

        this.setAttribute("position", new Float32BufferAttribute(position, 3));

        return this;
      }

      updateFromObject(object) {
        let geometry = object.geometry;

        if (object.isMesh) {
          let direct = geometry.__directGeometry;

          if (geometry.elementsNeedUpdate === true) {
            direct = undefined;
            geometry.elementsNeedUpdate = false;
          }

          if (direct === undefined) {
            return this.fromGeometry(geometry);
          }

          direct.verticesNeedUpdate = geometry.verticesNeedUpdate;
          direct.normalsNeedUpdate = geometry.normalsNeedUpdate;
          direct.colorsNeedUpdate = geometry.colorsNeedUpdate;
          direct.uvsNeedUpdate = geometry.uvsNeedUpdate;
          direct.groupsNeedUpdate = geometry.groupsNeedUpdate;

          geometry.verticesNeedUpdate = false;
          geometry.normalsNeedUpdate = false;
          geometry.colorsNeedUpdate = false;
          geometry.uvsNeedUpdate = false;
          geometry.groupsNeedUpdate = false;

          geometry = direct;
        }

        if (geometry.verticesNeedUpdate === true) {
          const attribute = this.attributes.position;

          if (attribute !== undefined) {
            attribute.copyVector3sArray(geometry.vertices);
            attribute.needsUpdate = true;
          }

          geometry.verticesNeedUpdate = false;
        }

        if (geometry.normalsNeedUpdate === true) {
          const attribute = this.attributes.normal;

          if (attribute !== undefined) {
            attribute.copyVector3sArray(geometry.normals);
            attribute.needsUpdate = true;
          }

          geometry.normalsNeedUpdate = false;
        }

        if (geometry.colorsNeedUpdate === true) {
          const attribute = this.attributes.color;

          if (attribute !== undefined) {
            attribute.copyColorsArray(geometry.colors);
            attribute.needsUpdate = true;
          }

          geometry.colorsNeedUpdate = false;
        }

        if (geometry.uvsNeedUpdate) {
          const attribute = this.attributes.uv;

          if (attribute !== undefined) {
            attribute.copyVector2sArray(geometry.uvs);
            attribute.needsUpdate = true;
          }

          geometry.uvsNeedUpdate = false;
        }

        if (geometry.lineDistancesNeedUpdate) {
          const attribute = this.attributes.lineDistance;

          if (attribute !== undefined) {
            attribute.copyArray(geometry.lineDistances);
            attribute.needsUpdate = true;
          }

          geometry.lineDistancesNeedUpdate = false;
        }

        if (geometry.groupsNeedUpdate) {
          geometry.computeGroups(object.geometry);
          this.groups = geometry.groups;

          geometry.groupsNeedUpdate = false;
        }

        return this;
      }

      fromGeometry(geometry) {
        geometry.__directGeometry = new DirectGeometry().fromGeometry(geometry);

        return this.fromDirectGeometry(geometry.__directGeometry);
      }

      fromDirectGeometry(geometry) {
        const positions = new Float32Array(geometry.vertices.length * 3);
        this.setAttribute(
          "position",
          new BufferAttribute(positions, 3).copyVector3sArray(geometry.vertices)
        );

        if (geometry.normals.length > 0) {
          const normals = new Float32Array(geometry.normals.length * 3);
          this.setAttribute(
            "normal",
            new BufferAttribute(normals, 3).copyVector3sArray(geometry.normals)
          );
        }

        if (geometry.colors.length > 0) {
          const colors = new Float32Array(geometry.colors.length * 3);
          this.setAttribute(
            "color",
            new BufferAttribute(colors, 3).copyColorsArray(geometry.colors)
          );
        }

        if (geometry.uvs.length > 0) {
          const uvs = new Float32Array(geometry.uvs.length * 2);
          this.setAttribute(
            "uv",
            new BufferAttribute(uvs, 2).copyVector2sArray(geometry.uvs)
          );
        }

        if (geometry.uvs2.length > 0) {
          const uvs2 = new Float32Array(geometry.uvs2.length * 2);
          this.setAttribute(
            "uv2",
            new BufferAttribute(uvs2, 2).copyVector2sArray(geometry.uvs2)
          );
        }

        // groups
        this.groups = geometry.groups;

        if (geometry.boundingSphere !== null) {
          this.boundingSphere = geometry.boundingSphere.clone();
        }

        if (geometry.boundingBox !== null) {
          this.boundingBox = geometry.boundingBox.clone();
        }

        return this;
      }

      computeBoundingBox() {
        if (this.boundingBox === null) {
          this.boundingBox = new Box3();
        }

        const position = this.attributes.position;
        if (position !== undefined) {
          this.boundingBox.setFromBufferAttribute(position);
        } else {
          this.boundingBox.makeEmpty();
        }

        if (Number.isNaN(this.boundingBox.min.x) || Number.isNaN(this.boundingBox.min.y) || Number.isNaN(this.boundingBox.min.z)) {
          console.error(
            'THREE.BufferGeometry.computeBoundingBox(): Computed min/max have NaN values. The "position" attribute is likely to have NaN values.',
            this
          );
        }
      }

      computeBoundingSphere() {
        if (this.boundingSphere === null) {
          this.boundingSphere = new Sphere();
        }

        const position = this.attributes.position;

        if (position) {
          // first, find the center of the bounding sphere
          const center = this.boundingSphere.center;

          _box$2.setFromBufferAttribute(position);

          _box$2.getCenter(center);

          // second, try to find a boundingSphere with a radius smaller than the
          // boundingSphere of the boundingBox: sqrt(3) smaller in the best case

          let maxRadiusSq = 0;

          for (let i = 0, il = position.count; i < il; i++) {
            _vector$4.fromBufferAttribute(position, i);

            maxRadiusSq = Math.max(
              maxRadiusSq,
              center.distanceToSquared(_vector$4)
            );
          }

          this.boundingSphere.radius = Math.sqrt(maxRadiusSq);

          if (Number.isNaN(this.boundingSphere.radius)) {
            console.error(
              'THREE.BufferGeometry.computeBoundingSphere(): Computed radius is NaN. The "position" attribute is likely to have NaN values.',
              this
            );
          }
        }
      }

      computeVertexNormals() {
        const index = this.index;
        const positionAttribute = this.getAttribute("position");

        if (positionAttribute !== undefined) {
          let normalAttribute = this.getAttribute("normal");

          if (normalAttribute === undefined) {
            normalAttribute = new BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);
            this.setAttribute("normal", normalAttribute);
          } else {
            // reset existing normals to zero
            for (let i = 0, il = normalAttribute.count; i < il; i++) {
              normalAttribute.setXYZ(i, 0, 0, 0);
            }
          }

          const pA = new Vector3(),
            pB = new Vector3(),
            pC = new Vector3();
          const nA = new Vector3(),
            nB = new Vector3(),
            nC = new Vector3();
          const cb = new Vector3(),
            ab = new Vector3();

          // indexed elements

          if (index) {
            for (let i = 0, il = index.count; i < il; i += 3) {
              const vA = index.getX(i + 0);
              const vB = index.getX(i + 1);
              const vC = index.getX(i + 2);

              pA.fromBufferAttribute(positionAttribute, vA);
              pB.fromBufferAttribute(positionAttribute, vB);
              pC.fromBufferAttribute(positionAttribute, vC);

              cb.subVectors(pC, pB);
              ab.subVectors(pA, pB);
              cb.cross(ab);

              nA.fromBufferAttribute(normalAttribute, vA);
              nB.fromBufferAttribute(normalAttribute, vB);
              nC.fromBufferAttribute(normalAttribute, vC);

              nA.add(cb);
              nB.add(cb);
              nC.add(cb);

              normalAttribute.setXYZ(vA, nA.x, nA.y, nA.z);
              normalAttribute.setXYZ(vB, nB.x, nB.y, nB.z);
              normalAttribute.setXYZ(vC, nC.x, nC.y, nC.z);
            }
          } else {
            // non-indexed elements (unconnected triangle soup)
            for (let i = 0, il = positionAttribute.count; i < il; i += 3) {
              pA.fromBufferAttribute(positionAttribute, i + 0);
              pB.fromBufferAttribute(positionAttribute, i + 1);
              pC.fromBufferAttribute(positionAttribute, i + 2);

              cb.subVectors(pC, pB);
              ab.subVectors(pA, pB);
              cb.cross(ab);

              normalAttribute.setXYZ(i + 0, cb.x, cb.y, cb.z);
              normalAttribute.setXYZ(i + 1, cb.x, cb.y, cb.z);
              normalAttribute.setXYZ(i + 2, cb.x, cb.y, cb.z);
            }
          }

          this.normalizeNormals();

          normalAttribute.needsUpdate = true;
        }
      }

      merge(geometry, offset) {
        if (!(geometry && geometry.isBufferGeometry)) {
          console.error("THREE.BufferGeometry.merge(): geometry not an instance of THREE.BufferGeometry.", geometry);
          return;
        }

        if (offset === undefined) {
          offset = 0;

          console.warn(
            "THREE.BufferGeometry.merge(): Overwriting original geometry, starting at offset=0. " +
              "Use BufferGeometryUtils.mergeBufferGeometries() for lossless merge."
          );
        }

        const attributes = this.attributes;

        for (const key in attributes) {
          if (geometry.attributes[key] === undefined) continue;

          const attribute1 = attributes[key];
          const attributeArray1 = attribute1.array;

          const attribute2 = geometry.attributes[key];
          const attributeArray2 = attribute2.array;

          const attributeOffset = attribute2.itemSize * offset;
          const length = Math.min(
            attributeArray2.length,
            attributeArray1.length - attributeOffset
          );

          for (let i = 0, j = attributeOffset; i < length; i++, j++) {
            attributeArray1[j] = attributeArray2[i];
          }
        }

        return this;
      }

      normalizeNormals() {
        const normals = this.attributes.normal;

        for (let i = 0, il = normals.count; i < il; i++) {
          _vector$4.fromBufferAttribute(normals, i);

          _vector$4.normalize();

          normals.setXYZ(i, _vector$4.x, _vector$4.y, _vector$4.z);
        }
      }

      toNonIndexed() {
        function convertBufferAttribute(attribute, indices) {
          const array = attribute.array;
          const itemSize = attribute.itemSize;
          const normalized = attribute.normalized;

          const array2 = new array.constructor(indices.length * itemSize);

          let index = 0,
            index2 = 0;

          for (let i = 0, l = indices.length; i < l; i++) {
            index = indices[i] * itemSize;

            for (let j = 0; j < itemSize; j++) {
              array2[index2++] = array[index++];
            }
          }

          return new BufferAttribute(array2, itemSize, normalized);
        }

        if (this.index === null) {
          console.warn("THREE.BufferGeometry.toNonIndexed(): Geometry is already non-indexed.");
          return this;
        }

        const geometry2 = new BufferGeometry();

        const indices = this.index.array;
        const attributes = this.attributes;

        // attributes

        for (const name in attributes) {
          const attribute = attributes[name];

          const newAttribute = convertBufferAttribute(attribute, indices);

          geometry2.setAttribute(name, newAttribute);
        }

        // groups

        const groups = this.groups;

        for (let i = 0, l = groups.length; i < l; i++) {
          const group = groups[i];
          geometry2.addGroup(group.start, group.count, group.materialIndex);
        }

        return geometry2;
      }

      clone() {
        return new BufferGeometry().copy(this);
      }

      copy(source) {
        // reset
        this.index = null;
        this.attributes = {};
        this.groups = [];
        this.boundingBox = null;
        this.boundingSphere = null;

        // used for storing cloned, shared data
        const data = {};

        // name
        this.name = source.name;

        // index
        const index = source.index;
        if (index !== null) {
          this.setIndex(index.clone(data));
        }

        // attributes
        const attributes = source.attributes;
        for (const name in attributes) {
          const attribute = attributes[name];
          this.setAttribute(name, attribute.clone(data));
        }

        // groups
        const groups = source.groups;
        for (let i = 0, l = groups.length; i < l; i++) {
          const group = groups[i];
          this.addGroup(group.start, group.count, group.materialIndex);
        }

        // bounding box
        const boundingBox = source.boundingBox;
        if (boundingBox !== null) {
          this.boundingBox = boundingBox.clone();
        }

        // bounding sphere
        const boundingSphere = source.boundingSphere;
        if (boundingSphere !== null) {
          this.boundingSphere = boundingSphere.clone();
        }

        // draw range
        this.drawRange.start = source.drawRange.start;
        this.drawRange.count = source.drawRange.count;

        // user data
        this.userData = source.userData;

        return this;
      }

      dispose() {
        this.dispatchEvent({ type: "dispose" });
      }
}

export class Geometry extends EventDispatcher {
    isGeometry = true;

    constructor() {
        super();
        Object.defineProperty(this, "id", { value: (_geometryId += 2) });

        this.uuid = generateUUID();

        this.name = "";
        this.type = "Geometry";

        this.vertices = [];
        this.colors = [];
        this.faces = [];
        this.faceVertexUvs = [[]];

        this.lineDistances = [];

        this.boundingBox = null;
        this.boundingSphere = null;

        // update flags
        this.elementsNeedUpdate = false;
        this.verticesNeedUpdate = false;
        this.uvsNeedUpdate = false;
        this.normalsNeedUpdate = false;
        this.colorsNeedUpdate = false;
        this.lineDistancesNeedUpdate = false;
        this.groupsNeedUpdate = false;
    }

    applyMatrix4(matrix) {
        const normalMatrix = new Matrix3().getNormalMatrix(matrix);

        for (let i = 0, il = this.vertices.length; i < il; i++) {
          const vertex = this.vertices[i];
          vertex.applyMatrix4(matrix);
        }

        for (let i = 0, il = this.faces.length; i < il; i++) {
          const face = this.faces[i];
          face.normal.applyMatrix3(normalMatrix).normalize();

          for (let j = 0, jl = face.vertexNormals.length; j < jl; j++) {
            face.vertexNormals[j].applyMatrix3(normalMatrix).normalize();
          }
        }

        if (this.boundingBox !== null) {
          this.computeBoundingBox();
        }

        if (this.boundingSphere !== null) {
          this.computeBoundingSphere();
        }

        this.verticesNeedUpdate = true;
        this.normalsNeedUpdate = true;

        return this;
      }

      rotateX(angle) {
        // rotate geometry around world x-axis

        _m1$3.makeRotationX(angle);

        this.applyMatrix4(_m1$3);

        return this;
      }

      rotateY(angle) {
        // rotate geometry around world y-axis

        _m1$3.makeRotationY(angle);

        this.applyMatrix4(_m1$3);

        return this;
      }

      rotateZ(angle) {
        // rotate geometry around world z-axis

        _m1$3.makeRotationZ(angle);

        this.applyMatrix4(_m1$3);

        return this;
      }

      translate(x, y, z) {
        // translate geometry

        _m1$3.makeTranslation(x, y, z);

        this.applyMatrix4(_m1$3);

        return this;
      }

      scale(x, y, z) {
        // scale geometry

        _m1$3.makeScale(x, y, z);

        this.applyMatrix4(_m1$3);

        return this;
      }

      fromBufferGeometry(geometry) {
        const scope = this;

        const index = geometry.index !== null ? geometry.index : undefined;
        const attributes = geometry.attributes;

        if (attributes.position === undefined) {
          console.error(
            "THREE.Geometry.fromBufferGeometry(): Position attribute required for conversion."
          );
          return this;
        }

        const position = attributes.position;
        const normal = attributes.normal;
        const color = attributes.color;
        const uv = attributes.uv;
        const uv2 = attributes.uv2;

        if (uv2 !== undefined) this.faceVertexUvs[1] = [];

        for (let i = 0; i < position.count; i++) {
          scope.vertices.push(new Vector3().fromBufferAttribute(position, i));

          if (color !== undefined) {
            scope.colors.push(new Color().fromBufferAttribute(color, i));
          }
        }

        function addFace(a, b, c, materialIndex) {
          const vertexColors =
            color === undefined
              ? []
              : [
                  scope.colors[a].clone(),
                  scope.colors[b].clone(),
                  scope.colors[c].clone(),
                ];

          const vertexNormals =
            normal === undefined
              ? []
              : [
                  new Vector3().fromBufferAttribute(normal, a),
                  new Vector3().fromBufferAttribute(normal, b),
                  new Vector3().fromBufferAttribute(normal, c),
                ];

          const face = new Face3(
            a,
            b,
            c,
            vertexNormals,
            vertexColors,
            materialIndex
          );

          scope.faces.push(face);

          if (uv !== undefined) {
            scope.faceVertexUvs[0].push([
              new Vector2().fromBufferAttribute(uv, a),
              new Vector2().fromBufferAttribute(uv, b),
              new Vector2().fromBufferAttribute(uv, c),
            ]);
          }

          if (uv2 !== undefined) {
            scope.faceVertexUvs[1].push([
              new Vector2().fromBufferAttribute(uv2, a),
              new Vector2().fromBufferAttribute(uv2, b),
              new Vector2().fromBufferAttribute(uv2, c),
            ]);
          }
        }

        const groups = geometry.groups;

        if (groups.length > 0) {
          for (let i = 0; i < groups.length; i++) {
            const group = groups[i];

            const start = group.start;
            const count = group.count;

            for (let j = start, jl = start + count; j < jl; j += 3) {
              if (index !== undefined) {
                addFace(
                  index.getX(j),
                  index.getX(j + 1),
                  index.getX(j + 2),
                  group.materialIndex
                );
              } else {
                addFace(j, j + 1, j + 2, group.materialIndex);
              }
            }
          }
        } else {
          if (index !== undefined) {
            for (let i = 0; i < index.count; i += 3) {
              addFace(index.getX(i), index.getX(i + 1), index.getX(i + 2));
            }
          } else {
            for (let i = 0; i < position.count; i += 3) {
              addFace(i, i + 1, i + 2);
            }
          }
        }

        this.computeFaceNormals();

        if (geometry.boundingBox !== null) {
          this.boundingBox = geometry.boundingBox.clone();
        }

        if (geometry.boundingSphere !== null) {
          this.boundingSphere = geometry.boundingSphere.clone();
        }

        return this;
      }

      center() {
        this.computeBoundingBox();

        this.boundingBox.getCenter(_offset).negate();

        this.translate(_offset.x, _offset.y, _offset.z);

        return this;
      }

      normalize() {
        this.computeBoundingSphere();

        const center = this.boundingSphere.center;
        const radius = this.boundingSphere.radius;

        const s = radius === 0 ? 1 : 1.0 / radius;

        const matrix = new Matrix4();
        matrix.set(
          s,
          0,
          0,
          -s * center.x,
          0,
          s,
          0,
          -s * center.y,
          0,
          0,
          s,
          -s * center.z,
          0,
          0,
          0,
          1
        );

        this.applyMatrix4(matrix);

        return this;
      }

      computeFaceNormals() {
        const cb = new Vector3(),
          ab = new Vector3();

        for (let f = 0, fl = this.faces.length; f < fl; f++) {
          const face = this.faces[f];

          const vA = this.vertices[face.a];
          const vB = this.vertices[face.b];
          const vC = this.vertices[face.c];

          cb.subVectors(vC, vB);
          ab.subVectors(vA, vB);
          cb.cross(ab);

          cb.normalize();

          face.normal.copy(cb);
        }
      }

      computeVertexNormals(areaWeighted = true) {
        const vertices = new Array(this.vertices.length);

        for (let v = 0, vl = this.vertices.length; v < vl; v++) {
          vertices[v] = new Vector3();
        }

        if (areaWeighted) {
          // vertex normals weighted by triangle areas
          // http://www.iquilezles.org/www/articles/normals/normals.htm

          const cb = new Vector3(),
            ab = new Vector3();

          for (let f = 0, fl = this.faces.length; f < fl; f++) {
            const face = this.faces[f];

            const vA = this.vertices[face.a];
            const vB = this.vertices[face.b];
            const vC = this.vertices[face.c];

            cb.subVectors(vC, vB);
            ab.subVectors(vA, vB);
            cb.cross(ab);

            vertices[face.a].add(cb);
            vertices[face.b].add(cb);
            vertices[face.c].add(cb);
          }
        } else {
          this.computeFaceNormals();

          for (let f = 0, fl = this.faces.length; f < fl; f++) {
            const face = this.faces[f];

            vertices[face.a].add(face.normal);
            vertices[face.b].add(face.normal);
            vertices[face.c].add(face.normal);
          }
        }

        for (let v = 0, vl = this.vertices.length; v < vl; v++) {
          vertices[v].normalize();
        }

        for (let f = 0, fl = this.faces.length; f < fl; f++) {
          const face = this.faces[f];

          const vertexNormals = face.vertexNormals;

          if (vertexNormals.length === 3) {
            vertexNormals[0].copy(vertices[face.a]);
            vertexNormals[1].copy(vertices[face.b]);
            vertexNormals[2].copy(vertices[face.c]);
          } else {
            vertexNormals[0] = vertices[face.a].clone();
            vertexNormals[1] = vertices[face.b].clone();
            vertexNormals[2] = vertices[face.c].clone();
          }
        }

        if (this.faces.length > 0) {
          this.normalsNeedUpdate = true;
        }
      }

      computeFlatVertexNormals() {
        this.computeFaceNormals();

        for (let f = 0, fl = this.faces.length; f < fl; f++) {
          const face = this.faces[f];

          const vertexNormals = face.vertexNormals;

          if (vertexNormals.length === 3) {
            vertexNormals[0].copy(face.normal);
            vertexNormals[1].copy(face.normal);
            vertexNormals[2].copy(face.normal);
          } else {
            vertexNormals[0] = face.normal.clone();
            vertexNormals[1] = face.normal.clone();
            vertexNormals[2] = face.normal.clone();
          }
        }

        if (this.faces.length > 0) {
          this.normalsNeedUpdate = true;
        }
      }

      computeBoundingBox() {
        if (this.boundingBox === null) {
          this.boundingBox = new Box3();
        }

        this.boundingBox.setFromPoints(this.vertices);
      }

      computeBoundingSphere() {
        if (this.boundingSphere === null) {
          this.boundingSphere = new Sphere();
        }

        this.boundingSphere.setFromPoints(this.vertices);
      }

      merge(geometry, matrix, materialIndexOffset = 0) {
        if (!(geometry && geometry.isGeometry)) {
          console.error(
            "THREE.Geometry.merge(): geometry not an instance of THREE.Geometry.",
            geometry
          );
          return;
        }

        let normalMatrix;
        const vertexOffset = this.vertices.length,
          vertices1 = this.vertices,
          vertices2 = geometry.vertices,
          faces1 = this.faces,
          faces2 = geometry.faces,
          colors1 = this.colors,
          colors2 = geometry.colors;

        if (matrix !== undefined) {
          normalMatrix = new Matrix3().getNormalMatrix(matrix);
        }

        // vertices

        for (let i = 0, il = vertices2.length; i < il; i++) {
          const vertex = vertices2[i];

          const vertexCopy = vertex.clone();

          if (matrix !== undefined) vertexCopy.applyMatrix4(matrix);

          vertices1.push(vertexCopy);
        }

        // colors

        for (let i = 0, il = colors2.length; i < il; i++) {
          colors1.push(colors2[i].clone());
        }

        // faces

        for (let i = 0, il = faces2.length; i < il; i++) {
          const face = faces2[i];
          let normal, color;
          const faceVertexNormals = face.vertexNormals,
            faceVertexColors = face.vertexColors;

          const faceCopy = new Face3(
            face.a + vertexOffset,
            face.b + vertexOffset,
            face.c + vertexOffset
          );
          faceCopy.normal.copy(face.normal);

          if (normalMatrix !== undefined) {
            faceCopy.normal.applyMatrix3(normalMatrix).normalize();
          }

          for (let j = 0, jl = faceVertexNormals.length; j < jl; j++) {
            normal = faceVertexNormals[j].clone();

            if (normalMatrix !== undefined) {
              normal.applyMatrix3(normalMatrix).normalize();
            }

            faceCopy.vertexNormals.push(normal);
          }

          faceCopy.color.copy(face.color);

          for (let j = 0, jl = faceVertexColors.length; j < jl; j++) {
            color = faceVertexColors[j];
            faceCopy.vertexColors.push(color.clone());
          }

          faceCopy.materialIndex = face.materialIndex + materialIndexOffset;

          faces1.push(faceCopy);
        }

        // uvs

        for (let i = 0, il = geometry.faceVertexUvs.length; i < il; i++) {
          const faceVertexUvs2 = geometry.faceVertexUvs[i];

          if (this.faceVertexUvs[i] === undefined) this.faceVertexUvs[i] = [];

          for (let j = 0, jl = faceVertexUvs2.length; j < jl; j++) {
            const uvs2 = faceVertexUvs2[j],
              uvsCopy = [];

            for (let k = 0, kl = uvs2.length; k < kl; k++) {
              uvsCopy.push(uvs2[k].clone());
            }

            this.faceVertexUvs[i].push(uvsCopy);
          }
        }
      }

      mergeMesh(mesh) {
        if (!(mesh && mesh.isMesh)) {
          console.error(
            "THREE.Geometry.mergeMesh(): mesh not an instance of THREE.Mesh.",
            mesh
          );
          return;
        }

        if (mesh.matrixAutoUpdate) mesh.updateMatrix();

        this.merge(mesh.geometry, mesh.matrix);
      }

      /*
       * Checks for duplicate vertices with hashmap.
       * Duplicated vertices are removed
       * and faces' vertices are updated.
       */

      mergeVertices(precisionPoints = 4) {
        const verticesMap = {}; // Hashmap for looking up vertices by position coordinates (and making sure they are unique)
        const unique = [],
          changes = [];

        const precision = 10 ** precisionPoints;

        for (let i = 0, il = this.vertices.length; i < il; i++) {
          const v = this.vertices[i];
          const key =
            `${Math.round(v.x * precision)}_${Math.round(v.y * precision)}_${Math.round(v.z * precision)}`;

          if (verticesMap[key] === undefined) {
            verticesMap[key] = i;
            unique.push(this.vertices[i]);
            changes[i] = unique.length - 1;
          } else {
            changes[i] = changes[verticesMap[key]];
          }
        }

        // if faces are completely degenerate after merging vertices, we
        // have to remove them from the geometry.
        const faceIndicesToRemove = [];

        for (let i = 0, il = this.faces.length; i < il; i++) {
          const face = this.faces[i];

          face.a = changes[face.a];
          face.b = changes[face.b];
          face.c = changes[face.c];

          const indices = [face.a, face.b, face.c];

          // if any duplicate vertices are found in a Face3
          // we have to remove the face as nothing can be saved
          for (let n = 0; n < 3; n++) {
            if (indices[n] === indices[(n + 1) % 3]) {
              faceIndicesToRemove.push(i);
              break;
            }
          }
        }

        for (let i = faceIndicesToRemove.length - 1; i >= 0; i--) {
          const idx = faceIndicesToRemove[i];

          this.faces.splice(idx, 1);

          for (let j = 0, jl = this.faceVertexUvs.length; j < jl; j++) {
            this.faceVertexUvs[j].splice(idx, 1);
          }
        }

        // Use unique set of vertices

        const diff = this.vertices.length - unique.length;
        this.vertices = unique;
        return diff;
      }

      setFromPoints(points) {
        this.vertices = [];

        for (let i = 0, l = points.length; i < l; i++) {
          const point = points[i];
          this.vertices.push(new Vector3(point.x, point.y, point.z || 0));
        }

        return this;
      }

      sortFacesByMaterialIndex() {
        const faces = this.faces;
        const length = faces.length;

        // tag faces

        for (let i = 0; i < length; i++) {
          faces[i]._id = i;
        }

        // sort faces

        function materialIndexSort(a, b) {
          return a.materialIndex - b.materialIndex;
        }

        faces.sort(materialIndexSort);

        // sort uvs

        const uvs1 = this.faceVertexUvs[0];
        const uvs2 = this.faceVertexUvs[1];

        let newUvs1, newUvs2;

        if (uvs1 && uvs1.length === length) newUvs1 = [];
        if (uvs2 && uvs2.length === length) newUvs2 = [];

        for (let i = 0; i < length; i++) {
          const id = faces[i]._id;

          if (newUvs1) newUvs1.push(uvs1[id]);
          if (newUvs2) newUvs2.push(uvs2[id]);
        }

        if (newUvs1) this.faceVertexUvs[0] = newUvs1;
        if (newUvs2) this.faceVertexUvs[1] = newUvs2;
      }

      clone() {
        return new Geometry().copy(this);
      }

      copy(source) {
        // reset

        this.vertices = [];
        this.colors = [];
        this.faces = [];
        this.faceVertexUvs = [[]];
        this.lineDistances = [];
        this.boundingBox = null;
        this.boundingSphere = null;

        // name

        this.name = source.name;

        // vertices

        const vertices = source.vertices;

        for (let i = 0, il = vertices.length; i < il; i++) {
          this.vertices.push(vertices[i].clone());
        }

        // colors

        const colors = source.colors;

        for (let i = 0, il = colors.length; i < il; i++) {
          this.colors.push(colors[i].clone());
        }

        // faces

        const faces = source.faces;

        for (let i = 0, il = faces.length; i < il; i++) {
          this.faces.push(faces[i].clone());
        }

        // face vertex uvs

        for (let i = 0, il = source.faceVertexUvs.length; i < il; i++) {
          const faceVertexUvs = source.faceVertexUvs[i];

          if (this.faceVertexUvs[i] === undefined) {
            this.faceVertexUvs[i] = [];
          }

          for (let j = 0, jl = faceVertexUvs.length; j < jl; j++) {
            const uvs = faceVertexUvs[j],
              uvsCopy = [];

            for (let k = 0, kl = uvs.length; k < kl; k++) {
              const uv = uvs[k];

              uvsCopy.push(uv.clone());
            }

            this.faceVertexUvs[i].push(uvsCopy);
          }
        }

        // line distances

        const lineDistances = source.lineDistances;

        for (let i = 0, il = lineDistances.length; i < il; i++) {
          this.lineDistances.push(lineDistances[i]);
        }

        // bounding box

        const boundingBox = source.boundingBox;

        if (boundingBox !== null) {
          this.boundingBox = boundingBox.clone();
        }

        // bounding sphere

        const boundingSphere = source.boundingSphere;

        if (boundingSphere !== null) {
          this.boundingSphere = boundingSphere.clone();
        }

        // update flags

        this.elementsNeedUpdate = source.elementsNeedUpdate;
        this.verticesNeedUpdate = source.verticesNeedUpdate;
        this.uvsNeedUpdate = source.uvsNeedUpdate;
        this.normalsNeedUpdate = source.normalsNeedUpdate;
        this.colorsNeedUpdate = source.colorsNeedUpdate;
        this.lineDistancesNeedUpdate = source.lineDistancesNeedUpdate;
        this.groupsNeedUpdate = source.groupsNeedUpdate;

        return this;
      }

      dispose() {
        this.dispatchEvent({ type: "dispose" });
      }
}


export class BoxGeometry extends Geometry {
    constructor(
      width,
      height,
      depth,
      widthSegments,
      heightSegments,
      depthSegments
    ) {
      super();

      this.type = "BoxGeometry";

      this.parameters = {
        width: width,
        height: height,
        depth: depth,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
        depthSegments: depthSegments,
      };

      this.fromBufferGeometry(
        new BoxBufferGeometry(
          width,
          height,
          depth,
          widthSegments,
          heightSegments,
          depthSegments
        )
      );
      this.mergeVertices();
    }
  }

export class PlaneGeometry extends Geometry {
    constructor(width, height, widthSegments, heightSegments) {
      super();

      this.type = "PlaneGeometry";

      this.parameters = {
        width: width,
        height: height,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
      };

      this.fromBufferGeometry(
        new PlaneBufferGeometry(width, height, widthSegments, heightSegments)
      );
      this.mergeVertices();
    }
  }

export class PlaneBufferGeometry extends BufferGeometry {
    constructor(width = 1, height = 1, widthSegments = 1, heightSegments = 1) {
      super();
      this.type = "PlaneBufferGeometry";

      this.parameters = {
        width: width,
        height: height,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
      };

      const width_half = width / 2;
      const height_half = height / 2;

      const gridX = Math.floor(widthSegments);
      const gridY = Math.floor(heightSegments);

      const gridX1 = gridX + 1;
      const gridY1 = gridY + 1;

      const segment_width = width / gridX;
      const segment_height = height / gridY;

      //

      const indices = [];
      const vertices = [];
      const normals = [];
      const uvs = [];

      for (let iy = 0; iy < gridY1; iy++) {
        const y = iy * segment_height - height_half;

        for (let ix = 0; ix < gridX1; ix++) {
          const x = ix * segment_width - width_half;

          vertices.push(x, -y, 0);

          normals.push(0, 0, 1);

          uvs.push(ix / gridX);
          uvs.push(1 - iy / gridY);
        }
      }

      for (let iy = 0; iy < gridY; iy++) {
        for (let ix = 0; ix < gridX; ix++) {
          const a = ix + gridX1 * iy;
          const b = ix + gridX1 * (iy + 1);
          const c = ix + 1 + gridX1 * (iy + 1);
          const d = ix + 1 + gridX1 * iy;

          indices.push(a, b, d);
          indices.push(b, c, d);
        }
      }

      this.setIndex(indices);
      this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
      this.setAttribute("normal", new Float32BufferAttribute(normals, 3));
      this.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
    }
}

export class SphereBufferGeometry extends BufferGeometry {
    constructor(
      radius = 1,
      widthSegments = 8,
      heightSegments = 6,
      phiStart = 0,
      phiLength = Math.PI * 2,
      thetaStart = 0,
      thetaLength = Math.PI
    ) {
      super();
      this.type = "SphereBufferGeometry";

      this.parameters = {
        radius: radius,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
        phiStart: phiStart,
        phiLength: phiLength,
        thetaStart: thetaStart,
        thetaLength: thetaLength,
      };

      widthSegments = Math.max(3, Math.floor(widthSegments));
      heightSegments = Math.max(2, Math.floor(heightSegments));

      const thetaEnd = Math.min(thetaStart + thetaLength, Math.PI);

      let index = 0;
      const grid = [];

      const vertex = new Vector3();
      const normal = new Vector3();

      // buffers

      const indices = [];
      const vertices = [];
      const normals = [];
      const uvs = [];

      // generate vertices, normals and uvs

      for (let iy = 0; iy <= heightSegments; iy++) {
        const verticesRow = [];

        const v = iy / heightSegments;

        // special case for the poles

        let uOffset = 0;

        if (iy === 0 && thetaStart === 0) {
          uOffset = 0.5 / widthSegments;
        } else if (iy === heightSegments && thetaEnd === Math.PI) {
          uOffset = -0.5 / widthSegments;
        }

        for (let ix = 0; ix <= widthSegments; ix++) {
          const u = ix / widthSegments;

          // vertex

          vertex.x =
            -radius *
            Math.cos(phiStart + u * phiLength) *
            Math.sin(thetaStart + v * thetaLength);
          vertex.y = radius * Math.cos(thetaStart + v * thetaLength);
          vertex.z =
            radius *
            Math.sin(phiStart + u * phiLength) *
            Math.sin(thetaStart + v * thetaLength);

          vertices.push(vertex.x, vertex.y, vertex.z);

          // normal

          normal.copy(vertex).normalize();
          normals.push(normal.x, normal.y, normal.z);

          // uv

          uvs.push(u + uOffset, 1 - v);

          verticesRow.push(index++);
        }

        grid.push(verticesRow);
      }

      // indices

      for (let iy = 0; iy < heightSegments; iy++) {
        for (let ix = 0; ix < widthSegments; ix++) {
          const a = grid[iy][ix + 1];
          const b = grid[iy][ix];
          const c = grid[iy + 1][ix];
          const d = grid[iy + 1][ix + 1];

          if (iy !== 0 || thetaStart > 0) indices.push(a, b, d);
          if (iy !== heightSegments - 1 || thetaEnd < Math.PI)
            indices.push(b, c, d);
        }
      }

      // build geometry

      this.setIndex(indices);
      this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
      this.setAttribute("normal", new Float32BufferAttribute(normals, 3));
      this.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
    }
}

export class SphereGeometry extends Geometry {
    constructor(
      radius,
      widthSegments,
      heightSegments,
      phiStart,
      phiLength,
      thetaStart,
      thetaLength
    ) {
      super();
      this.type = "SphereGeometry";

      this.parameters = {
        radius: radius,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
        phiStart: phiStart,
        phiLength: phiLength,
        thetaStart: thetaStart,
        thetaLength: thetaLength,
      };

      this.fromBufferGeometry(
        new SphereBufferGeometry(
          radius,
          widthSegments,
          heightSegments,
          phiStart,
          phiLength,
          thetaStart,
          thetaLength
        )
      );
      this.mergeVertices();
    }
}

export class TorusBufferGeometry extends BufferGeometry {
    constructor(
      radius = 1,
      tube = 0.4,
      radialSegments = 8,
      tubularSegments = 6,
      arc = Math.PI * 2
    ) {
      super();
      this.type = "TorusBufferGeometry";

      this.parameters = {
        radius: radius,
        tube: tube,
        radialSegments: radialSegments,
        tubularSegments: tubularSegments,
        arc: arc,
      };

      radialSegments = Math.floor(radialSegments);
      tubularSegments = Math.floor(tubularSegments);

      // buffers

      const indices = [];
      const vertices = [];
      const normals = [];
      const uvs = [];

      // helper variables

      const center = new Vector3();
      const vertex = new Vector3();
      const normal = new Vector3();

      // generate vertices, normals and uvs

      for (let j = 0; j <= radialSegments; j++) {
        for (let i = 0; i <= tubularSegments; i++) {
          const u = (i / tubularSegments) * arc;
          const v = (j / radialSegments) * Math.PI * 2;

          // vertex

          vertex.x = (radius + tube * Math.cos(v)) * Math.cos(u);
          vertex.y = (radius + tube * Math.cos(v)) * Math.sin(u);
          vertex.z = tube * Math.sin(v);

          vertices.push(vertex.x, vertex.y, vertex.z);

          // normal

          center.x = radius * Math.cos(u);
          center.y = radius * Math.sin(u);
          normal.subVectors(vertex, center).normalize();

          normals.push(normal.x, normal.y, normal.z);

          // uv

          uvs.push(i / tubularSegments);
          uvs.push(j / radialSegments);
        }
      }

      // generate indices

      for (let j = 1; j <= radialSegments; j++) {
        for (let i = 1; i <= tubularSegments; i++) {
          // indices

          const a = (tubularSegments + 1) * j + i - 1;
          const b = (tubularSegments + 1) * (j - 1) + i - 1;
          const c = (tubularSegments + 1) * (j - 1) + i;
          const d = (tubularSegments + 1) * j + i;

          // faces

          indices.push(a, b, d);
          indices.push(b, c, d);
        }
      }

      // build geometry

      this.setIndex(indices);
      this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
      this.setAttribute("normal", new Float32BufferAttribute(normals, 3));
      this.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
    }
}

export class TorusGeometry extends Geometry {
    constructor(radius, tube, radialSegments, tubularSegments, arc) {
      super();
      this.type = "TorusGeometry";

      this.parameters = {
        radius: radius,
        tube: tube,
        radialSegments: radialSegments,
        tubularSegments: tubularSegments,
        arc: arc,
      };

      this.fromBufferGeometry(
        new TorusBufferGeometry(
          radius,
          tube,
          radialSegments,
          tubularSegments,
          arc
        )
      );
      this.mergeVertices();
    }
}

export class WireframeGeometry extends BufferGeometry {
    constructor(geometry) {
      super();
      this.type = "WireframeGeometry";

      // buffer

      const vertices = [];

      // helper variables

      const edge = [0, 0],
        edges = {};
      const keys = ["a", "b", "c"];

      // different logic for Geometry and BufferGeometry

      if (geometry?.isGeometry) {
        // create a data structure that contains all edges without duplicates

        const faces = geometry.faces;

        for (let i = 0, l = faces.length; i < l; i++) {
          const face = faces[i];

          for (let j = 0; j < 3; j++) {
            const edge1 = face[keys[j]];
            const edge2 = face[keys[(j + 1) % 3]];
            edge[0] = Math.min(edge1, edge2); // sorting prevents duplicates
            edge[1] = Math.max(edge1, edge2);

            const key = `${edge[0]},${edge[1]}`;

            if (edges[key] === undefined) {
              edges[key] = { index1: edge[0], index2: edge[1] };
            }
          }
        }

        // generate vertices

        for (const key in edges) {
          const e = edges[key];

          let vertex = geometry.vertices[e.index1];
          vertices.push(vertex.x, vertex.y, vertex.z);

          vertex = geometry.vertices[e.index2];
          vertices.push(vertex.x, vertex.y, vertex.z);
        }
      } else if (geometry && geometry.isBufferGeometry) {
        const vertex = new Vector3();

        if (geometry.index !== null) {
          // indexed BufferGeometry

          const position = geometry.attributes.position;
          const indices = geometry.index;
          let groups = geometry.groups;

          if (groups.length === 0) {
            groups = [{ start: 0, count: indices.count, materialIndex: 0 }];
          }

          // create a data structure that contains all eges without duplicates

          for (let o = 0, ol = groups.length; o < ol; ++o) {
            const group = groups[o];

            const start = group.start;
            const count = group.count;

            for (let i = start, l = start + count; i < l; i += 3) {
              for (let j = 0; j < 3; j++) {
                const edge1 = indices.getX(i + j);
                const edge2 = indices.getX(i + ((j + 1) % 3));
                edge[0] = Math.min(edge1, edge2); // sorting prevents duplicates
                edge[1] = Math.max(edge1, edge2);

                const key = `${edge[0]},${edge[1]}`;

                if (edges[key] === undefined) {
                  edges[key] = { index1: edge[0], index2: edge[1] };
                }
              }
            }
          }

          // generate vertices

          for (const key in edges) {
            const e = edges[key];

            vertex.fromBufferAttribute(position, e.index1);
            vertices.push(vertex.x, vertex.y, vertex.z);

            vertex.fromBufferAttribute(position, e.index2);
            vertices.push(vertex.x, vertex.y, vertex.z);
          }
        } else {
          // non-indexed BufferGeometry

          const position = geometry.attributes.position;

          for (let i = 0, l = position.count / 3; i < l; i++) {
            for (let j = 0; j < 3; j++) {
              // three edges per triangle, an edge is represented as (index1, index2)
              // e.g. the first triangle has the following edges: (0,1),(1,2),(2,0)

              const index1 = 3 * i + j;
              vertex.fromBufferAttribute(position, index1);
              vertices.push(vertex.x, vertex.y, vertex.z);

              const index2 = 3 * i + ((j + 1) % 3);
              vertex.fromBufferAttribute(position, index2);
              vertices.push(vertex.x, vertex.y, vertex.z);
            }
          }
        }
      }

      // build geometry

      this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
    }
}


class BoxBufferGeometry extends BufferGeometry {
    constructor(
      width = 1,
      height = 1,
      depth = 1,
      widthSegments = 1,
      heightSegments = 1,
      depthSegments = 1
    ) {
      super();

      this.type = "BoxBufferGeometry";

      this.parameters = {
        width: width,
        height: height,
        depth: depth,
        widthSegments: widthSegments,
        heightSegments: heightSegments,
        depthSegments: depthSegments,
      };

      const scope = this;

      // segments

      widthSegments = Math.floor(widthSegments);
      heightSegments = Math.floor(heightSegments);
      depthSegments = Math.floor(depthSegments);

      // buffers

      const indices = [];
      const vertices = [];
      const normals = [];
      const uvs = [];

      // helper variables

      let numberOfVertices = 0;
      let groupStart = 0;

      // build each side of the box geometry

      buildPlane(
        "z",
        "y",
        "x",
        -1,
        -1,
        depth,
        height,
        width,
        depthSegments,
        heightSegments,
        0
      ); // px
      buildPlane(
        "z",
        "y",
        "x",
        1,
        -1,
        depth,
        height,
        -width,
        depthSegments,
        heightSegments,
        1
      ); // nx
      buildPlane(
        "x",
        "z",
        "y",
        1,
        1,
        width,
        depth,
        height,
        widthSegments,
        depthSegments,
        2
      ); // py
      buildPlane(
        "x",
        "z",
        "y",
        1,
        -1,
        width,
        depth,
        -height,
        widthSegments,
        depthSegments,
        3
      ); // ny
      buildPlane(
        "x",
        "y",
        "z",
        1,
        -1,
        width,
        height,
        depth,
        widthSegments,
        heightSegments,
        4
      ); // pz
      buildPlane(
        "x",
        "y",
        "z",
        -1,
        -1,
        width,
        height,
        -depth,
        widthSegments,
        heightSegments,
        5
      ); // nz

      // build geometry

      this.setIndex(indices);
      this.setAttribute("position", new Float32BufferAttribute(vertices, 3));
      this.setAttribute("normal", new Float32BufferAttribute(normals, 3));
      this.setAttribute("uv", new Float32BufferAttribute(uvs, 2));

      function buildPlane(
        u,
        v,
        w,
        udir,
        vdir,
        width,
        height,
        depth,
        gridX,
        gridY,
        materialIndex
      ) {
        const segmentWidth = width / gridX;
        const segmentHeight = height / gridY;

        const widthHalf = width / 2;
        const heightHalf = height / 2;
        const depthHalf = depth / 2;

        const gridX1 = gridX + 1;
        const gridY1 = gridY + 1;

        let vertexCounter = 0;
        let groupCount = 0;

        const vector = new Vector3();

        // generate vertices, normals and uvs

        for (let iy = 0; iy < gridY1; iy++) {
          const y = iy * segmentHeight - heightHalf;

          for (let ix = 0; ix < gridX1; ix++) {
            const x = ix * segmentWidth - widthHalf;

            // set values to correct vector component

            vector[u] = x * udir;
            vector[v] = y * vdir;
            vector[w] = depthHalf;

            // now apply vector to vertex buffer

            vertices.push(vector.x, vector.y, vector.z);

            // set values to correct vector component

            vector[u] = 0;
            vector[v] = 0;
            vector[w] = depth > 0 ? 1 : -1;

            // now apply vector to normal buffer

            normals.push(vector.x, vector.y, vector.z);

            // uvs

            uvs.push(ix / gridX);
            uvs.push(1 - iy / gridY);

            // counters

            vertexCounter += 1;
          }
        }

        // indices

        // 1. you need three indices to draw a single face
        // 2. a single segment consists of two faces
        // 3. so we need to generate six (2*3) indices per segment

        for (let iy = 0; iy < gridY; iy++) {
          for (let ix = 0; ix < gridX; ix++) {
            const a = numberOfVertices + ix + gridX1 * iy;
            const b = numberOfVertices + ix + gridX1 * (iy + 1);
            const c = numberOfVertices + (ix + 1) + gridX1 * (iy + 1);
            const d = numberOfVertices + (ix + 1) + gridX1 * iy;

            // faces

            indices.push(a, b, d);
            indices.push(b, c, d);

            // increase counter

            groupCount += 6;
          }
        }

        // add a group to the geometry. this will ensure multi material support

        scope.addGroup(groupStart, groupCount, materialIndex);

        // calculate new start value for groups

        groupStart += groupCount;

        // update total number of vertices

        numberOfVertices += vertexCounter;
      }
    }
}

const _intersectionPoint = new Vector3();
const _intersectionPointWorld = new Vector3();

export function checkIntersection(object, material, raycaster, ray, pA, pB, pC, point) {
    let intersect;

    if (material.side === BackSide) {
      intersect = ray.intersectTriangle(pC, pB, pA, true, point);
    } else {
      intersect = ray.intersectTriangle(
        pA,
        pB,
        pC,
        material.side !== DoubleSide,
        point
      );
    }

    if (intersect === null) return null;

    _intersectionPointWorld.copy(point);
    _intersectionPointWorld.applyMatrix4(object.matrixWorld);

    const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

    if (distance < raycaster.near || distance > raycaster.far) return null;

    return {
      distance: distance,
      point: _intersectionPointWorld.clone(),
      object: object,
    };
}

export class Face3 {
  constructor(a, b, c, normal, color, materialIndex = 0) {
    this.a = a;
    this.b = b;
    this.c = c;

    this.normal = normal?.isVector3 ? normal : new Vector3();
    this.vertexNormals = Array.isArray(normal) ? normal : [];

    this.color = color?.isColor ? color : new Color();
    this.vertexColors = Array.isArray(color) ? color : [];

    this.materialIndex = materialIndex;
  }

  clone() {
    return new this.constructor().copy(this);
  }

  copy(source) {
    this.a = source.a;
    this.b = source.b;
    this.c = source.c;

    this.normal.copy(source.normal);
    this.color.copy(source.color);

    this.materialIndex = source.materialIndex;

    for (let i = 0, il = source.vertexNormals.length; i < il; i++) {
      this.vertexNormals[i] = source.vertexNormals[i].clone();
    }

    for (let i = 0, il = source.vertexColors.length; i < il; i++) {
      this.vertexColors[i] = source.vertexColors[i].clone();
    }

    return this;
  }
}

const _vA = new Vector3();
const _vB = new Vector3();
const _vC = new Vector3();

const _uvA = new Vector2();
const _uvB = new Vector2();
const _uvC = new Vector2();

export function checkBufferGeometryIntersection(object, material, raycaster, ray, position, uv, uv2, a, b, c) {
    _vA.fromBufferAttribute(position, a);
    _vB.fromBufferAttribute(position, b);
    _vC.fromBufferAttribute(position, c);

    const intersection = checkIntersection(
      object,
      material,
      raycaster,
      ray,
      _vA,
      _vB,
      _vC,
      _intersectionPoint
    );

    if (intersection) {
      if (uv) {
        _uvA.fromBufferAttribute(uv, a);
        _uvB.fromBufferAttribute(uv, b);
        _uvC.fromBufferAttribute(uv, c);

        intersection.uv = Triangle.getUV(
          _intersectionPoint,
          _vA,
          _vB,
          _vC,
          _uvA,
          _uvB,
          _uvC,
          new Vector2()
        );
      }

      if (uv2) {
        _uvA.fromBufferAttribute(uv2, a);
        _uvB.fromBufferAttribute(uv2, b);
        _uvC.fromBufferAttribute(uv2, c);

        intersection.uv2 = Triangle.getUV(
          _intersectionPoint,
          _vA,
          _vB,
          _vC,
          _uvA,
          _uvB,
          _uvC,
          new Vector2()
        );
      }

      const face = new Face3(a, b, c);
      Triangle.getNormal(_vA, _vB, _vC, face.normal);

      intersection.face = face;
    }

    return intersection;
}

const _v0$1 = /*@__PURE__*/ new Vector3();
const _v1$3 = /*@__PURE__*/ new Vector3();
const _v2$1 = /*@__PURE__*/ new Vector3();
const _v3 = /*@__PURE__*/ new Vector3();

const _vab = /*@__PURE__*/ new Vector3();
const _vac = /*@__PURE__*/ new Vector3();
const _vbc = /*@__PURE__*/ new Vector3();
const _vap = /*@__PURE__*/ new Vector3();
const _vbp = /*@__PURE__*/ new Vector3();
const _vcp = /*@__PURE__*/ new Vector3();

export class Triangle {
  constructor(a, b, c) {
    this.a = a ?? new Vector3();
    this.b = b ?? new Vector3();
    this.c = c ?? new Vector3();
  }

  static getNormal(a, b, c, target) {
    target.subVectors(c, b);
    _v0$1.subVectors(a, b);
    target.cross(_v0$1);

    const targetLengthSq = target.lengthSq();
    if (targetLengthSq > 0) {
      return target.multiplyScalar(1 / Math.sqrt(targetLengthSq));
    }

    return target.set(0, 0, 0);
  }

  // static/instance method to calculate barycentric coordinates
  // based on: http://www.blackpawn.com/texts/pointinpoly/default.html
  static getBarycoord(point, a, b, c, target) {
    _v0$1.subVectors(c, a);
    _v1$3.subVectors(b, a);
    _v2$1.subVectors(point, a);

    const dot00 = _v0$1.dot(_v0$1);
    const dot01 = _v0$1.dot(_v1$3);
    const dot02 = _v0$1.dot(_v2$1);
    const dot11 = _v1$3.dot(_v1$3);
    const dot12 = _v1$3.dot(_v2$1);

    const denom = dot00 * dot11 - dot01 * dot01;

    // collinear or singular triangle
    if (denom === 0) {
      // arbitrary location outside of triangle?
      // not sure if this is the best idea, maybe should be returning undefined
      return target.set(-2, -1, -1);
    }

    const invDenom = 1 / denom;
    const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
    const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

    // barycentric coordinates must always sum to 1
    return target.set(1 - u - v, v, u);
  }

  static containsPoint(point, a, b, c) {
    Triangle.getBarycoord(point, a, b, c, _v3);

    return _v3.x >= 0 && _v3.y >= 0 && _v3.x + _v3.y <= 1;
  }

  static getUV(point, p1, p2, p3, uv1, uv2, uv3, target) {
    Triangle.getBarycoord(point, p1, p2, p3, _v3);

    target.set(0, 0);
    target.addScaledVector(uv1, _v3.x);
    target.addScaledVector(uv2, _v3.y);
    target.addScaledVector(uv3, _v3.z);

    return target;
  }

  static isFrontFacing(a, b, c, direction) {
    _v0$1.subVectors(c, b);
    _v1$3.subVectors(a, b);

    // strictly front facing
    return _v0$1.cross(_v1$3).dot(direction) < 0 ? true : false;
  }

  set(a, b, c) {
    this.a.copy(a);
    this.b.copy(b);
    this.c.copy(c);

    return this;
  }

  setFromPointsAndIndices(points, i0, i1, i2) {
    this.a.copy(points[i0]);
    this.b.copy(points[i1]);
    this.c.copy(points[i2]);

    return this;
  }

  clone() {
    return new this.constructor().copy(this);
  }

  copy(triangle) {
    this.a.copy(triangle.a);
    this.b.copy(triangle.b);
    this.c.copy(triangle.c);

    return this;
  }

  getArea() {
    _v0$1.subVectors(this.c, this.b);
    _v1$3.subVectors(this.a, this.b);

    return _v0$1.cross(_v1$3).length() * 0.5;
  }

  getMidpoint(target) {
    return target
      .addVectors(this.a, this.b)
      .add(this.c)
      .multiplyScalar(1 / 3);
  }

  getNormal(target) {
    return Triangle.getNormal(this.a, this.b, this.c, target);
  }

  getPlane(target) {
    return target.setFromCoplanarPoints(this.a, this.b, this.c);
  }

  getBarycoord(point, target) {
    return Triangle.getBarycoord(point, this.a, this.b, this.c, target);
  }

  getUV(point, uv1, uv2, uv3, target) {
    return Triangle.getUV(
      point,
      this.a,
      this.b,
      this.c,
      uv1,
      uv2,
      uv3,
      target
    );
  }

  containsPoint(point) {
    return Triangle.containsPoint(point, this.a, this.b, this.c);
  }

  isFrontFacing(direction) {
    return Triangle.isFrontFacing(this.a, this.b, this.c, direction);
  }

  intersectsBox(box) {
    return box.intersectsTriangle(this);
  }

  closestPointToPoint(p, target) {
    const a = this.a,
      b = this.b,
      c = this.c;
    let v, w;

    // algorithm thanks to Real-Time Collision Detection by Christer Ericson,
    // published by Morgan Kaufmann Publishers, (c) 2005 Elsevier Inc.,
    // under the accompanying license; see chapter 5.1.5 for detailed explanation.
    // basically, we're distinguishing which of the voronoi regions of the triangle
    // the point lies in with the minimum amount of redundant computation.

    _vab.subVectors(b, a);
    _vac.subVectors(c, a);
    _vap.subVectors(p, a);
    const d1 = _vab.dot(_vap);
    const d2 = _vac.dot(_vap);
    if (d1 <= 0 && d2 <= 0) {
      // vertex region of A; barycentric coords (1, 0, 0)
      return target.copy(a);
    }

    _vbp.subVectors(p, b);
    const d3 = _vab.dot(_vbp);
    const d4 = _vac.dot(_vbp);
    if (d3 >= 0 && d4 <= d3) {
      // vertex region of B; barycentric coords (0, 1, 0)
      return target.copy(b);
    }

    const vc = d1 * d4 - d3 * d2;
    if (vc <= 0 && d1 >= 0 && d3 <= 0) {
      v = d1 / (d1 - d3);
      // edge region of AB; barycentric coords (1-v, v, 0)
      return target.copy(a).addScaledVector(_vab, v);
    }

    _vcp.subVectors(p, c);
    const d5 = _vab.dot(_vcp);
    const d6 = _vac.dot(_vcp);
    if (d6 >= 0 && d5 <= d6) {
      // vertex region of C; barycentric coords (0, 0, 1)
      return target.copy(c);
    }

    const vb = d5 * d2 - d1 * d6;
    if (vb <= 0 && d2 >= 0 && d6 <= 0) {
      w = d2 / (d2 - d6);
      // edge region of AC; barycentric coords (1-w, 0, w)
      return target.copy(a).addScaledVector(_vac, w);
    }

    const va = d3 * d6 - d5 * d4;
    if (va <= 0 && d4 - d3 >= 0 && d5 - d6 >= 0) {
      _vbc.subVectors(c, b);
      w = (d4 - d3) / (d4 - d3 + (d5 - d6));
      // edge region of BC; barycentric coords (0, 1-w, w)
      return target.copy(b).addScaledVector(_vbc, w); // edge region of BC
    }

    // face region
    const denom = 1 / (va + vb + vc);
    // u = va * denom
    v = vb * denom;
    w = vc * denom;

    return target.copy(a).addScaledVector(_vab, v).addScaledVector(_vac, w);
  }

  equals(triangle) {
    return (
      triangle.a.equals(this.a) &&
      triangle.b.equals(this.b) &&
      triangle.c.equals(this.c)
    );
  }
}

const _vector1 = /*@__PURE__*/ new Vector3();
const _vector2 = /*@__PURE__*/ new Vector3();
const _normalMatrix = /*@__PURE__*/ new Matrix3();

export class Plane {
  isPlane = true;

  constructor(normal, constant) {
    this.normal = normal ?? new Vector3(1, 0, 0);
    this.constant = constant ?? 0;
  }

  set(normal, constant) {
    this.normal.copy(normal);
    this.constant = constant;

    return this;
  }

  setComponents(x, y, z, w) {
    this.normal.set(x, y, z);
    this.constant = w;

    return this;
  }

  setFromNormalAndCoplanarPoint(normal, point) {
    this.normal.copy(normal);
    this.constant = -point.dot(this.normal);

    return this;
  }

  setFromCoplanarPoints(a, b, c) {
    const normal = _vector1
      .subVectors(c, b)
      .cross(_vector2.subVectors(a, b))
      .normalize();

    this.setFromNormalAndCoplanarPoint(normal, a);

    return this;
  }

  clone() {
    return new this.constructor().copy(this);
  }

  copy(plane) {
    this.normal.copy(plane.normal);
    this.constant = plane.constant;

    return this;
  }

  normalize() {
    // Note: will lead to a divide by zero if the plane is invalid.

    const inverseNormalLength = 1.0 / this.normal.length();
    this.normal.multiplyScalar(inverseNormalLength);
    this.constant *= inverseNormalLength;

    return this;
  }

  negate() {
    this.constant *= -1;
    this.normal.negate();

    return this;
  }

  distanceToPoint(point) {
    return this.normal.dot(point) + this.constant;
  }

  distanceToSphere(sphere) {
    return this.distanceToPoint(sphere.center) - sphere.radius;
  }

  projectPoint(point, target) {
    return target
      .copy(this.normal)
      .multiplyScalar(-this.distanceToPoint(point))
      .add(point);
  }

  intersectLine(line, target) {
    const direction = line.delta(_vector1);

    const denominator = this.normal.dot(direction);

    if (denominator === 0) {
      // line is coplanar, return origin
      if (this.distanceToPoint(line.start) === 0) {
        return target.copy(line.start);
      }

      // Unsure if this is the correct method to handle this case.
      return undefined;
    }

    const t = -(line.start.dot(this.normal) + this.constant) / denominator;

    if (t < 0 || t > 1) {
      return undefined;
    }

    return target.copy(direction).multiplyScalar(t).add(line.start);
  }

  intersectsLine(line) {
    // Note: this tests if a line intersects the plane, not whether it (or its end-points) are coplanar with it.

    const startSign = this.distanceToPoint(line.start);
    const endSign = this.distanceToPoint(line.end);

    return (startSign < 0 && endSign > 0) || (endSign < 0 && startSign > 0);
  }

  intersectsBox(box) {
    return box.intersectsPlane(this);
  }

  intersectsSphere(sphere) {
    return sphere.intersectsPlane(this);
  }

  coplanarPoint(target) {
    return target.copy(this.normal).multiplyScalar(-this.constant);
  }

  applyMatrix4(matrix, optionalNormalMatrix) {
    const normalMatrix =
      optionalNormalMatrix || _normalMatrix.getNormalMatrix(matrix);

    const referencePoint = this.coplanarPoint(_vector1).applyMatrix4(matrix);

    const normal = this.normal.applyMatrix3(normalMatrix).normalize();

    this.constant = -referencePoint.dot(normal);

    return this;
  }

  translate(offset) {
    this.constant -= offset.dot(this.normal);

    return this;
  }

  equals(plane) {
    return (
      plane.normal.equals(this.normal) && plane.constant === this.constant
    );
  }
}

const _sphere$1 = /*@__PURE__*/ new Sphere();
const _vector$5 = /*@__PURE__*/ new Vector3();

export class Frustum {
    constructor(p0, p1, p2, p3, p4, p5) {
      this.planes = [
        p0 ?? new Plane(),
        p1 ?? new Plane(),
        p2 ?? new Plane(),
        p3 ?? new Plane(),
        p4 ?? new Plane(),
        p5 ?? new Plane(),
      ];
    }

    set(p0, p1, p2, p3, p4, p5) {
      const planes = this.planes;

      planes[0].copy(p0);
      planes[1].copy(p1);
      planes[2].copy(p2);
      planes[3].copy(p3);
      planes[4].copy(p4);
      planes[5].copy(p5);

      return this;
    }

    clone() {
      return new this.constructor().copy(this);
    }

    copy(frustum) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        planes[i].copy(frustum.planes[i]);
      }

      return this;
    }

    setFromProjectionMatrix(m) {
      const planes = this.planes;
      const me = m.elements;
      const me0 = me[0],
        me1 = me[1],
        me2 = me[2],
        me3 = me[3];
      const me4 = me[4],
        me5 = me[5],
        me6 = me[6],
        me7 = me[7];
      const me8 = me[8],
        me9 = me[9],
        me10 = me[10],
        me11 = me[11];
      const me12 = me[12],
        me13 = me[13],
        me14 = me[14],
        me15 = me[15];

      planes[0]
        .setComponents(me3 - me0, me7 - me4, me11 - me8, me15 - me12)
        .normalize();
      planes[1]
        .setComponents(me3 + me0, me7 + me4, me11 + me8, me15 + me12)
        .normalize();
      planes[2]
        .setComponents(me3 + me1, me7 + me5, me11 + me9, me15 + me13)
        .normalize();
      planes[3]
        .setComponents(me3 - me1, me7 - me5, me11 - me9, me15 - me13)
        .normalize();
      planes[4]
        .setComponents(me3 - me2, me7 - me6, me11 - me10, me15 - me14)
        .normalize();
      planes[5]
        .setComponents(me3 + me2, me7 + me6, me11 + me10, me15 + me14)
        .normalize();

      return this;
    }

    intersectsObject(object) {
      const geometry = object.geometry;

      if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

      _sphere$1.copy(geometry.boundingSphere).applyMatrix4(object.matrixWorld);

      return this.intersectsSphere(_sphere$1);
    }

    intersectsSprite(sprite) {
      _sphere$1.center.set(0, 0, 0);
      _sphere$1.radius = 0.7071067811865476;
      _sphere$1.applyMatrix4(sprite.matrixWorld);

      return this.intersectsSphere(_sphere$1);
    }

    intersectsSphere(sphere) {
      const planes = this.planes;
      const center = sphere.center;
      const negRadius = -sphere.radius;

      for (let i = 0; i < 6; i++) {
        const distance = planes[i].distanceToPoint(center);

        if (distance < negRadius) {
          return false;
        }
      }

      return true;
    }

    intersectsBox(box) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        const plane = planes[i];

        // corner at max distance

        _vector$5.x = plane.normal.x > 0 ? box.max.x : box.min.x;
        _vector$5.y = plane.normal.y > 0 ? box.max.y : box.min.y;
        _vector$5.z = plane.normal.z > 0 ? box.max.z : box.min.z;

        if (plane.distanceToPoint(_vector$5) < 0) {
          return false;
        }
      }

      return true;
    }

    containsPoint(point) {
      const planes = this.planes;

      for (let i = 0; i < 6; i++) {
        if (planes[i].distanceToPoint(point) < 0) {
          return false;
        }
      }

      return true;
    }
}

function checkSQBoxIntersection(object, raycaster, ray, pA, pB, pC, point) {
  let intersect;

  intersect = ray.intersectTriangle(
    pA,
    pB,
    pC,
    true,
    point
  );

  if (intersect === null) return null;

  _intersectionPointWorld.copy(point);
  _intersectionPointWorld.applyMatrix4(object.matrixWorld);

  const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

  if (distance < raycaster.near || distance > raycaster.far) return null;

  return {
    distance: distance,
    point: _intersectionPointWorld.clone(),
    object: object,
  };
}

const _intersectPoint = new Vector3();
const _inverseMatrix = new Matrix4();
const _ray = new Ray();
const _sphere = new Sphere();

const _pA = new Vector3();
const _pB = new Vector3();
const _pC = new Vector3();

export class Mesh extends Object3D {
  constructor(geometry = new BufferGeometry(), material = new MeshBasicMaterial()) {
    super();

    this.type = "Mesh";
    this.isMesh = true;

    this.geometry = geometry;
    this.material = material;
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const material = this.material;
    const matrixWorld = this.matrixWorld;

    if (material === undefined) return;

    // Checking boundingSphere distance to ray
    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere.copy(geometry.boundingSphere);
    _sphere.applyMatrix4(matrixWorld);

    if (raycaster.ray.intersectsSphere(_sphere) === false) return;

    _inverseMatrix.copy(matrixWorld).invert();
    _ray.copy(raycaster.ray).applyMatrix4(_inverseMatrix);

    // Check boundingBox before continuing
    if (geometry.boundingBox !== null) {
      if (_ray.intersectsBox(geometry.boundingBox) === false) return;
    }

    let intersection;

    if(geometry.isSQBoxBuffer) {
      const indices = geometry.indices;
      const vertices = geometry.vertices;

      for (let i = 0; i < indices.length; i += 3) {
        const a = indices[i] * 3;
        const b = indices[i + 1] * 3;
        const c = indices[i + 2] * 3;

        _pA.set(vertices.array[a],vertices.array[a + 1], vertices.array[a + 2]);
        _pB.set(vertices.array[b],vertices.array[b + 1], vertices.array[b + 2]);
        _pC.set(vertices.array[c],vertices.array[c + 1], vertices.array[c + 2]);

        intersection = checkSQBoxIntersection(
          this,
          raycaster,
          _ray,
          _pA,
          _pB,
          _pC,
          _intersectPoint
        );

        if (intersection) {
          intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics
          intersects.push(intersection);
        }
      }
    } else if (geometry.isBufferGeometry) {
      const index = geometry.index;
      const position = geometry.attributes.position;
      const uv = geometry.attributes.uv;
      const uv2 = geometry.attributes.uv2;
      const groups = geometry.groups;
      const drawRange = geometry.drawRange;

      if (index !== null) {
        // indexed buffer geometry

        if (Array.isArray(material)) {
          for (let i = 0, il = groups.length; i < il; i++) {
            const group = groups[i];
            const groupMaterial = material[group.materialIndex];

            const start = Math.max(group.start, drawRange.start);
            const end = Math.min(
              group.start + group.count,
              drawRange.start + drawRange.count
            );

            for (let j = start, jl = end; j < jl; j += 3) {
              const a = index.getX(j);
              const b = index.getX(j + 1);
              const c = index.getX(j + 2);

              intersection = checkBufferGeometryIntersection(
                this,
                groupMaterial,
                raycaster,
                _ray,
                position,
                uv,
                uv2,
                a,
                b,
                c
              );

              if (intersection) {
                intersection.faceIndex = Math.floor(j / 3); // triangle number in indexed buffer semantics
                intersection.face.materialIndex = group.materialIndex;
                intersects.push(intersection);
              }
            }
          }
        } else {
          const start = Math.max(0, drawRange.start);
          const end = Math.min(
            index.count,
            drawRange.start + drawRange.count
          );

          for (let i = start, il = end; i < il; i += 3) {
            const a = index.getX(i);
            const b = index.getX(i + 1);
            const c = index.getX(i + 2);

            intersection = checkBufferGeometryIntersection(
              this,
              material,
              raycaster,
              _ray,
              position,
              uv,
              uv2,
              a,
              b,
              c
            );

            if (intersection) {
              intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics
              intersects.push(intersection);
            }
          }
        }
      } else if (position !== undefined) {
        // non-indexed buffer geometry

        if (Array.isArray(material)) {
          for (let i = 0, il = groups.length; i < il; i++) {
            const group = groups[i];
            const groupMaterial = material[group.materialIndex];

            const start = Math.max(group.start, drawRange.start);
            const end = Math.min(
              group.start + group.count,
              drawRange.start + drawRange.count
            );

            for (let j = start, jl = end; j < jl; j += 3) {
              const a = j;
              const b = j + 1;
              const c = j + 2;

              intersection = checkBufferGeometryIntersection(
                this,
                groupMaterial,
                raycaster,
                _ray,
                position,
                uv,
                uv2,
                a,
                b,
                c
              );

              if (intersection) {
                intersection.faceIndex = Math.floor(j / 3); // triangle number in non-indexed buffer semantics
                intersection.face.materialIndex = group.materialIndex;
                intersects.push(intersection);
              }
            }
          }
        } else {
          const start = Math.max(0, drawRange.start);
          const end = Math.min(
            position.count,
            drawRange.start + drawRange.count
          );

          for (let i = start, il = end; i < il; i += 3) {
            const a = i;
            const b = i + 1;
            const c = i + 2;

            intersection = checkBufferGeometryIntersection(
              this,
              material,
              raycaster,
              _ray,
              position,
              uv,
              uv2,
              a,
              b,
              c
            );

            if (intersection) {
              intersection.faceIndex = Math.floor(i / 3); // triangle number in non-indexed buffer semantics
              intersects.push(intersection);
            }
          }
        }
      }
    } else if (geometry.isGeometry) {
      const isMultiMaterial = Array.isArray(material);

      const vertices = geometry.vertices;
      const faces = geometry.faces;
      let uvs;

      const faceVertexUvs = geometry.faceVertexUvs[0];
      if (faceVertexUvs.length > 0) uvs = faceVertexUvs;

      for (let f = 0, fl = faces.length; f < fl; f++) {
        const face = faces[f];
        const faceMaterial = isMultiMaterial
          ? material[face.materialIndex]
          : material;

        if (faceMaterial === undefined) continue;

        const fvA = vertices[face.a];
        const fvB = vertices[face.b];
        const fvC = vertices[face.c];

        intersection = checkIntersection(
          this,
          faceMaterial,
          raycaster,
          _ray,
          fvA,
          fvB,
          fvC,
          _intersectionPoint
        );

        if (intersection) {
          if (uvs?.[f]) {
            const uvs_f = uvs[f];
            _uvA.copy(uvs_f[0]);
            _uvB.copy(uvs_f[1]);
            _uvC.copy(uvs_f[2]);

            intersection.uv = Triangle.getUV(
              _intersectionPoint,
              fvA,
              fvB,
              fvC,
              _uvA,
              _uvB,
              _uvC,
              new Vector2()
            );
          }

          intersection.face = face;
          intersection.faceIndex = f;
          intersects.push(intersection);
        }
      }
    }
  }
}
