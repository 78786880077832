import React from "react";
import ReactDOM from "react-dom";

import { HOCAuthenticator } from "./authentication";
import "./App.css";
import { Viewer } from "./potree/potree.js";
import { Menu } from "./react/sidebar";
import { MessageList } from "react/messages";

const version = {
  major: 1,
  minor: 9,
  patch: 0,
};
console.log(
  "Skyqraft Lidar %d.%d.%d", version.major, version.minor, version.patch
);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

class App extends React.Component {
  componentDidMount = () => {
    window.name = "sq-lidar";

    /*
    * This only works because we never remount this component.
    * It is an awful initialization because we need ``potree_render_area`` for Viewer but we need Viewer for the Menu.
    */
    const viewer = window.viewer = new Viewer(document.getElementById("potree_render_area"));
    viewer.loadSettingsFromSearchParams(params);
    viewer.setControls(viewer.earthControls);
    viewer.loadGlobalData();

    // This depends on both ``sidebars`` and Viewer.
    ReactDOM.render(
      <Menu start_open={!params?.scene} />,
      document.getElementById("sidebars")
    );
    ReactDOM.render(<MessageList/>, document.getElementById("message_listing"));

    let targetLocation = {
      type: "NONE",
    };
    if (params?.image) {
      const image_id = parseInt(params.image);

      targetLocation = {
        type: "IMAGE",
        image_id: image_id,
      };
    } else if (params?.lng && params?.lat) {
      targetLocation = {
        type: "COORD",
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng),
      };

      if (params?.alt) {
        targetLocation.alt = parseFloat(params.alt);
      }

      if (params?.pitch && params?.yaw) {
        targetLocation.with_direction = true;
        targetLocation.pitch = parseFloat(params.pitch);
        targetLocation.yaw = parseFloat(params.yaw);
      }
    }
    viewer.targetLocation = targetLocation;


    const cloud = window.location.pathname;
    const project = cloud.split("/")[1];

    if (cloud !== "/" && cloud !== "") {
      viewer.loadProjectData(project);
      if (params?.scene) {
        loadCloud(params.scene);
      }
    }

    async function loadCloud(scene) {
      await viewer.initializedPromise;

      viewer.loadScene(scene);
    }
  };

  render() {
    return (
      <div
        className="potree_container"
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <div
          id="potree_render_area"
          style={{
            backgroundImage:
              "url('../build/potree/resources/images/background.jpg')",
          }}
        >
          <div id="distance_class_wrapper" className="distance_class_panel_hidden">
            <div id="distance_class_panel"></div>
          </div>
          <div id="message_listing" style={{
            "position": "absolute",
            "z-index": "1000",
            "right": "10px",
            "bottom": "10px"
          }}>
          </div>
        </div>
        <div id="sidebars"></div>
      </div>
    );
  }
}

export default HOCAuthenticator(App);
