import { ClampToEdgeWrapping, NearestFilter } from "./constants";
import { Texture } from "./types";

const WHITE = [1, 1, 1, 1];

export function recomputeDataTexture(
    input_data,
    output_data,
    width,
    fallback_color = WHITE
) {
    let valuesChanged = false;

    for (let i = 0; i < width; i++) {
        let color = fallback_color;
        let visible = true;

        if (input_data[i]) {
            color = input_data[i].color;
            visible = input_data[i].visible ?? true;
        }

        const r = 255 * color[0];
        const g = 255 * color[1];
        const b = 255 * color[2];
        const a = visible ? 255 * color[3] : 0;

        const out_i = 4*i;

        valuesChanged |= output_data[out_i + 0] !== r || output_data[out_i + 1] !== g || output_data[out_i + 2] !== b || output_data[out_i+ 3] !== a;

        output_data[out_i + 0] = r;
        output_data[out_i + 1] = g;
        output_data[out_i + 2] = b;
        output_data[out_i + 3] = a;
    }

    return valuesChanged;
}


export class DataTexture extends Texture {
    isDataTexture = true;

    constructor(data,
      width,
      height,
      format,
      type,
      mapping,
      wrapS,
      wrapT,
      magFilter,
      minFilter,
      anisotropy,
      encoding) {
      super(null, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy, encoding);

      this.image = { data: data || null, width: width || 1, height: height || 1 };

      this.magFilter = magFilter ?? NearestFilter;
      this.minFilter = minFilter ?? NearestFilter;

      this.generateMipmaps = false;
      this.flipY = false;
      this.unpackAlignment = 1;

      this.needsUpdate = true;
    }
  }

  export class DataTexture2DArray extends Texture {
    isDataTexture2DArray = true;

    constructor(data = null, width = 1, height = 1, depth = 1) {
      super(null);

      this.image = { data, width, height, depth };

      this.magFilter = NearestFilter;
      this.minFilter = NearestFilter;

      this.wrapR = ClampToEdgeWrapping;

      this.generateMipmaps = false;
      this.flipY = false;

      this.needsUpdate = true;
    }
  }

  export class DataTexture3D extends Texture {
    isDataTexture3D = true;

    constructor(data = null, width = 1, height = 1, depth = 1) {
      super(null);

      this.image = { data, width, height, depth };

      this.magFilter = NearestFilter;
      this.minFilter = NearestFilter;

      this.wrapR = ClampToEdgeWrapping;

      this.generateMipmaps = false;
      this.flipY = false;

      this.needsUpdate = true;
    }
  }
