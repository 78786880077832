import { BASE_URI } from "config";
import axios from "axios";
import { getProject } from "potree/potree";
//import { toast } from "react-toastify";

export async function authorizedGet<T = never, R = T>(
  path: String,
  header = {},
  options = {},
  project_id?: Number
): Promise<R> {
  try {
    // Perform the fetch
    const currentProject = getProject();
    const response = await axios.get(`${BASE_URI}${path}`, {
      withCredentials: true,
      headers: {
        MissionID: project_id ?? currentProject,
        ...header,
      },
      ...options,
    });

    /*if (response.status >= 400) {
      toast.error(`${response.status} - ${response.statusText}`);
    }*/

    return response.data;
  } catch (err) {
    return null;
  }
}

export async function authorizedGetBlob(
  path: string,
  header = {},
  options = {}
): Promise<BlobPart> {
  try {
    // Perform the fetch
    const currentProject = getProject();
    const response = await axios.get<BlobPart>(`${BASE_URI}${path}`, {
      withCredentials: true,
      headers: {
        MissionID: currentProject,
        ...header,
      },
      ...options,
    });

    /*if (response.status >= 400) {
      toast.error(`${response.status} - ${response.statusText}`);
    }*/
    return response.data;
  } catch (err) {
    return null;
  }
}

export async function authorizedPost<T = never, R = T>(
  path,
  body = {},
  header = {}
): Promise<R> {
  const currentProject = getProject();
  const response = await axios.post<R>(`${BASE_URI}${path}`, body, {
    withCredentials: true,
    headers: {
      MissionID: currentProject,
      "content-type": "application/json",
      ...header,
    },
  });

  return response.data;
}

export async function authorizedPut<T = never, R = T>(
  path,
  body = {},
  header = {}
): Promise<R> {
  const currentProject = getProject();
  const response = await axios.put<R>(`${BASE_URI}${path}`, body, {
    withCredentials: true,
    headers: {
      MissionID: currentProject,
      "content-type": "application/json",
      ...header,
    },
  });

  /*if (response.status >= 400) {
    toast.error(`${response.status} - ${response.statusText}`);
  }*/

  return response.data;
}

export async function authorizedDelete(path, header = {}) {
  const currentProject = getProject();
  const response = await axios.delete(`${BASE_URI}${path}`, {
    withCredentials: true,
    headers: {
      MissionID: currentProject,
      "content-type": "application/json",
      ...header,
    },
  });

  /*if (response.status >= 400) {
    toast.error(`${response.status} - ${response.statusText}`);
  }*/

  return response;
}

export function logOut() {
  axios
    .get(`${BASE_URI}/auth/logout`, {
      withCredentials: true,
    })
    .then(_ => {
      window.location.replace("/");
    });
}