import { Matrix4, Vector2, Vector3 } from "potree/mathtypes";
import { clamp } from "./math";

export class Curve {
    constructor() {
      this.type = "Curve";

      this.arcLengthDivisions = 200;
    }
    // Virtual base class method to overwrite and implement in subclasses
      //	- t [0 .. 1]

      getPoint(/* t, optionalTarget */) {
        console.warn("THREE.Curve: .getPoint() not implemented.");
        return null;
      }

      // Get point at relative position in curve according to arc length
      // - u [0 .. 1]

      getPointAt(u, optionalTarget) {
        const t = this.getUtoTmapping(u);
        return this.getPoint(t, optionalTarget);
      }

      // Get sequence of points using getPoint( t )

      getPoints(divisions = 5) {
        const points = [];

        for (let d = 0; d <= divisions; d++) {
          points.push(this.getPoint(d / divisions));
        }

        return points;
      }

      // Get sequence of points using getPointAt( u )

      getSpacedPoints(divisions = 5) {
        const points = [];

        for (let d = 0; d <= divisions; d++) {
          points.push(this.getPointAt(d / divisions));
        }

        return points;
      }

      // Get total curve arc length

      getLength() {
        const lengths = this.getLengths();
        return lengths[lengths.length - 1];
      }

      // Get list of cumulative segment lengths

      getLengths(divisions) {
        if (divisions === undefined) divisions = this.arcLengthDivisions;

        if (
          this.cacheArcLengths &&
          this.cacheArcLengths.length === divisions + 1 &&
          !this.needsUpdate
        ) {
          return this.cacheArcLengths;
        }

        this.needsUpdate = false;

        const cache = [];
        let current,
          last = this.getPoint(0);
        let sum = 0;

        cache.push(0);

        for (let p = 1; p <= divisions; p++) {
          current = this.getPoint(p / divisions);
          sum += current.distanceTo(last);
          cache.push(sum);
          last = current;
        }

        this.cacheArcLengths = cache;

        return cache; // { sums: cache, sum: sum }; Sum is in the last element.
      }

      updateArcLengths() {
        this.needsUpdate = true;
        this.getLengths();
      }

      // Given u ( 0 .. 1 ), get a t to find p. This gives you points which are equidistant

      getUtoTmapping(u, distance) {
        const arcLengths = this.getLengths();

        let i = 0;
        const il = arcLengths.length;

        let targetArcLength; // The targeted u distance value to get

        if (distance) {
          targetArcLength = distance;
        } else {
          targetArcLength = u * arcLengths[il - 1];
        }

        // binary search for the index with largest value smaller than target u distance

        let low = 0,
          high = il - 1,
          comparison;

        while (low <= high) {
          i = Math.floor(low + (high - low) / 2); // less likely to overflow, though probably not issue here, JS doesn't really have integers, all numbers are floats

          comparison = arcLengths[i] - targetArcLength;

          if (comparison < 0) {
            low = i + 1;
          } else if (comparison > 0) {
            high = i - 1;
          } else {
            high = i;
            break;

            // DONE
          }
        }

        i = high;

        if (arcLengths[i] === targetArcLength) {
          return i / (il - 1);
        }

        // we could get finer grain at lengths, or use simple interpolation between two points

        const lengthBefore = arcLengths[i];
        const lengthAfter = arcLengths[i + 1];

        const segmentLength = lengthAfter - lengthBefore;

        // determine where we are between the 'before' and 'after' points

        const segmentFraction = (targetArcLength - lengthBefore) / segmentLength;

        // add that fractional amount to t

        const t = (i + segmentFraction) / (il - 1);

        return t;
      }

      // Returns a unit vector tangent at t
      // In case any sub curve does not implement its tangent derivation,
      // 2 points a small delta apart will be used to find its gradient
      // which seems to give a reasonable approximation

      getTangent(t, optionalTarget) {
        const delta = 0.0001;
        let t1 = t - delta;
        let t2 = t + delta;

        // Capping in case of danger

        if (t1 < 0) t1 = 0;
        if (t2 > 1) t2 = 1;

        const pt1 = this.getPoint(t1);
        const pt2 = this.getPoint(t2);

        const tangent =
          optionalTarget || (pt1.isVector2 ? new Vector2() : new Vector3());

        tangent.copy(pt2).sub(pt1).normalize();

        return tangent;
      }

      getTangentAt(u, optionalTarget) {
        const t = this.getUtoTmapping(u);
        return this.getTangent(t, optionalTarget);
      }

      computeFrenetFrames(segments, closed) {
        // see http://www.cs.indiana.edu/pub/techreports/TR425.pdf

        const normal = new Vector3();

        const tangents = [];
        const normals = [];
        const binormals = [];

        const vec = new Vector3();
        const mat = new Matrix4();

        // compute the tangent vectors for each segment on the curve

        for (let i = 0; i <= segments; i++) {
          const u = i / segments;

          tangents[i] = this.getTangentAt(u, new Vector3());
          tangents[i].normalize();
        }

        // select an initial normal vector perpendicular to the first tangent vector,
        // and in the direction of the minimum tangent xyz component

        normals[0] = new Vector3();
        binormals[0] = new Vector3();
        let min = Number.MAX_VALUE;
        const tx = Math.abs(tangents[0].x);
        const ty = Math.abs(tangents[0].y);
        const tz = Math.abs(tangents[0].z);

        if (tx <= min) {
          min = tx;
          normal.set(1, 0, 0);
        }

        if (ty <= min) {
          min = ty;
          normal.set(0, 1, 0);
        }

        if (tz <= min) {
          normal.set(0, 0, 1);
        }

        vec.crossVectors(tangents[0], normal).normalize();

        normals[0].crossVectors(tangents[0], vec);
        binormals[0].crossVectors(tangents[0], normals[0]);

        // compute the slowly-varying normal and binormal vectors for each segment on the curve

        for (let i = 1; i <= segments; i++) {
          normals[i] = normals[i - 1].clone();

          binormals[i] = binormals[i - 1].clone();

          vec.crossVectors(tangents[i - 1], tangents[i]);

          if (vec.length() > Number.EPSILON) {
            vec.normalize();

            const theta = Math.acos(clamp(tangents[i - 1].dot(tangents[i]), -1, 1)); // clamp for floating pt errors

            normals[i].applyMatrix4(mat.makeRotationAxis(vec, theta));
          }

          binormals[i].crossVectors(tangents[i], normals[i]);
        }

        // if the curve is closed, postprocess the vectors so the first and last normal vectors are the same

        if (closed === true) {
          let theta = Math.acos(clamp(normals[0].dot(normals[segments]), -1, 1));
          theta /= segments;

          if (
            tangents[0].dot(vec.crossVectors(normals[0], normals[segments])) > 0
          ) {
            theta = -theta;
          }

          for (let i = 1; i <= segments; i++) {
            // twist a little...
            normals[i].applyMatrix4(mat.makeRotationAxis(tangents[i], theta * i));
            binormals[i].crossVectors(tangents[i], normals[i]);
          }
        }

        return {
          tangents: tangents,
          normals: normals,
          binormals: binormals,
        };
      }

      clone() {
        return new this.constructor().copy(this);
      }

      copy(source) {
        this.arcLengthDivisions = source.arcLengthDivisions;

        return this;
      }

      fromJSON(json) {
        this.arcLengthDivisions = json.arcLengthDivisions;

        return this;
      }
  }

    /**
     * Centripetal CatmullRom Curve - which is useful for avoiding
     * cusps and self-intersections in non-uniform catmull rom curves.
     * http://www.cemyuksel.com/research/catmullrom_param/catmullrom.pdf
     *
     * curve.type accepts centripetal(default), chordal and catmullrom
     * curve.tension is used for catmullrom which defaults to 0.5
     */

    /*
    Based on an optimized c++ solution in
     - http://stackoverflow.com/questions/9489736/catmull-rom-curve-with-no-cusps-and-no-self-intersections/
     - http://ideone.com/NoEbVM

    This CubicPoly class could be used for reusing some variables and calculations,
    but for three.js curve use, it could be possible inlined and flatten into a single function call
    which can be placed in CurveUtils.
    */

    function CubicPoly() {
      let c0 = 0,
        c1 = 0,
        c2 = 0,
        c3 = 0;

      /*
       * Compute coefficients for a cubic polynomial
       *   p(s) = c0 + c1*s + c2*s^2 + c3*s^3
       * such that
       *   p(0) = x0, p(1) = x1
       *  and
       *   p'(0) = t0, p'(1) = t1.
       */
      function init(x0, x1, t0, t1) {
        c0 = x0;
        c1 = t0;
        c2 = -3 * x0 + 3 * x1 - 2 * t0 - t1;
        c3 = 2 * x0 - 2 * x1 + t0 + t1;
      }

      return {
        initCatmullRom: (x0, x1, x2, x3, tension) => {
          init(x1, x2, tension * (x2 - x0), tension * (x3 - x1));
        },

        initNonuniformCatmullRom: (x0, x1, x2, x3, dt0, dt1, dt2) => {
          // compute tangents when parameterized in [t1,t2]
          let t1 = (x1 - x0) / dt0 - (x2 - x0) / (dt0 + dt1) + (x2 - x1) / dt1;
          let t2 = (x2 - x1) / dt1 - (x3 - x1) / (dt1 + dt2) + (x3 - x2) / dt2;

          // rescale tangents for parametrization in [0,1]
          t1 *= dt1;
          t2 *= dt1;

          init(x1, x2, t1, t2);
        },

        calc: (t) => {
          const t2 = t * t;
          const t3 = t2 * t;
          return c0 + c1 * t + c2 * t2 + c3 * t3;
        },
      };
    }

    //

    export const tmp = new Vector3();
    export const px = new CubicPoly(),
      py = new CubicPoly(),
      pz = new CubicPoly();

export class CatmullRomCurve3 extends Curve {
    isCatmullRomCurve3 = true;

    constructor(points = [],
      closed = false,
      curveType = "centripetal",
      tension = 0.5) {
        super();

      this.type = "CatmullRomCurve3";

      this.points = points;
      this.closed = closed;
      this.curveType = curveType;
      this.tension = tension;
    }
    getPoint(t,
      optionalTarget = new Vector3()) {
      const point = optionalTarget;

      const points = this.points;
      const l = points.length;

      const p = (l - (this.closed ? 0 : 1)) * t;
      let intPoint = Math.floor(p);
      let weight = p - intPoint;

      if (this.closed) {
        intPoint +=
          intPoint > 0 ? 0 : (Math.floor(Math.abs(intPoint) / l) + 1) * l;
      } else if (weight === 0 && intPoint === l - 1) {
        intPoint = l - 2;
        weight = 1;
      }

      let p0, p3; // 4 points (p1 & p2 defined below)

      if (this.closed || intPoint > 0) {
        p0 = points[(intPoint - 1) % l];
      } else {
        // extrapolate first point
        tmp.subVectors(points[0], points[1]).add(points[0]);
        p0 = tmp;
      }

      const p1 = points[intPoint % l];
      const p2 = points[(intPoint + 1) % l];

      if (this.closed || intPoint + 2 < l) {
        p3 = points[(intPoint + 2) % l];
      } else {
        // extrapolate last point
        tmp.subVectors(points[l - 1], points[l - 2]).add(points[l - 1]);
        p3 = tmp;
      }

      if (this.curveType === "centripetal" || this.curveType === "chordal") {
        // init Centripetal / Chordal Catmull-Rom
        const pow = this.curveType === "chordal" ? 0.5 : 0.25;
        let dt0 = p0.distanceToSquared(p1) ** pow;
        let dt1 = p1.distanceToSquared(p2) ** pow;
        let dt2 = p2.distanceToSquared(p3) ** pow;

        // safety check for repeated points
        if (dt1 < 1e-4) dt1 = 1.0;
        if (dt0 < 1e-4) dt0 = dt1;
        if (dt2 < 1e-4) dt2 = dt1;

        px.initNonuniformCatmullRom(p0.x, p1.x, p2.x, p3.x, dt0, dt1, dt2);
        py.initNonuniformCatmullRom(p0.y, p1.y, p2.y, p3.y, dt0, dt1, dt2);
        pz.initNonuniformCatmullRom(p0.z, p1.z, p2.z, p3.z, dt0, dt1, dt2);
      } else if (this.curveType === "catmullrom") {
        px.initCatmullRom(p0.x, p1.x, p2.x, p3.x, this.tension);
        py.initCatmullRom(p0.y, p1.y, p2.y, p3.y, this.tension);
        pz.initCatmullRom(p0.z, p1.z, p2.z, p3.z, this.tension);
      }

      point.set(px.calc(weight), py.calc(weight), pz.calc(weight));

      return point;
    }
    copy(source) {
      Curve.prototype.copy.call(this, source);

      this.points = [];

      for (let i = 0, l = source.points.length; i < l; i++) {
        const point = source.points[i];

        this.points.push(point.clone());
      }

      this.closed = source.closed;
      this.curveType = source.curveType;
      this.tension = source.tension;

      return this;
    }
  }
