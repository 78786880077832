import TWEEN from "tween.js";
import { BoxGeometry, Geometry, Mesh, PlaneBufferGeometry } from "../geometry";
import { Box3, Ray, Raycaster, Sphere, Vector3, Vector4 } from "../mathtypes";
import { LineSegments, Object3D, Scene } from "../object3d";
import { Camera, OrthographicCamera, PerspectiveCamera } from "../rendering/camera";
import { BackSide, NearestFilter, RGBAFormat, RGBFormat } from "../rendering/constants";
import { DataTexture } from "../rendering/datatexture";
import { loadTexture } from "../rendering/loaders";
import { LineBasicMaterial, MeshBasicMaterial } from "../rendering/material";

/**
 * adapted from mhluska at https://github.com/mrdoob/three.js/issues/1561
    */
export function computeTransformedBoundingBox(box, transform) {
    const vertices = [
        new Vector3(box.min.x, box.min.y, box.min.z).applyMatrix4(transform),
        new Vector3(box.min.x, box.min.y, box.min.z).applyMatrix4(transform),
        new Vector3(box.max.x, box.min.y, box.min.z).applyMatrix4(transform),
        new Vector3(box.min.x, box.max.y, box.min.z).applyMatrix4(transform),
        new Vector3(box.min.x, box.min.y, box.max.z).applyMatrix4(transform),
        new Vector3(box.min.x, box.max.y, box.max.z).applyMatrix4(transform),
        new Vector3(box.max.x, box.max.y, box.min.z).applyMatrix4(transform),
        new Vector3(box.max.x, box.min.y, box.max.z).applyMatrix4(transform),
        new Vector3(box.max.x, box.max.y, box.max.z).applyMatrix4(transform),
    ];

    const boundingBox = new Box3();
    boundingBox.setFromPoints(vertices);

    return boundingBox;
}

/**
 * add separators to large numbers
*
* @param nStr
* @returns
*/
export function addCommas(nStr) {
    nStr += "";
    const x = nStr.split(".");
    let x1 = x[0];
    const x2 = x.length > 1 ? `.${x[1]}` : "";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1,$2");
    }
    return x1 + x2;
}

export function moveTo(scene, endPosition, endTarget) {
    const view = scene.view;
    const camera = scene.getActiveCamera();
    const animationDuration = 500;
    const easing = TWEEN.Easing.Quartic.Out;

    {
        // animate camera position
        const tween = new TWEEN.Tween(view.position).to(
            endPosition,
            animationDuration
        );
        tween.easing(easing);
        tween.start();
    }

    {
        // animate camera target
        const camTargetDistance = camera.position.distanceTo(endTarget);
        const target = new Vector3().addVectors(
          camera.position,
          camera
            .getWorldDirection(new Vector3())
            .clone()
            .multiplyScalar(camTargetDistance)
        );
        const tween = new TWEEN.Tween(target).to(endTarget, animationDuration);
        tween.easing(easing);
        tween.onUpdate(() => {
            view.lookAt(target);
        });
        tween.onComplete(() => {
            view.lookAt(target);
        });
        tween.start();
    }
}

export function loadSkybox(path) {
    const parent = new Object3D("skybox_root");

    const camera = new PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        100000
    );
    camera.up.set(0, 0, 1);
    const scene = new Scene();

    const format = ".jpg";
    const urls = [
        `${path}px${format}`,
        `${path}nx${format}`,
        `${path}py${format}`,
        `${path}ny${format}`,
        `${path}pz${format}`,
        `${path}nz${format}`,
    ];

    const materialArray = [];
    for (let i = 0; i < 6; i++) {
        const material = new MeshBasicMaterial({
            map: null,
            side: BackSide,
            depthTest: false,
            depthWrite: false,
            color: 0x424556,
        });

        materialArray.push(material);

        loadTexture(
            urls[i],
            function loaded(texture) {
                material.map = texture;
                material.needsUpdate = true;
                material.color.setHex(0xffffff);
            },
            function error(error) {
                console.log("An error happened when loading skybox:", error);
            }
        );
    }

    const skyGeometry = new BoxGeometry(700, 700, 700);
    const skybox = new Mesh(skyGeometry, materialArray);

    scene.add(skybox);

    scene.traverse((n) => (n.frustumCulled = false));

    // z up
    scene.rotation.x = Math.PI / 2;

    parent.children.push(camera);
    camera.parent = parent;

    return { camera, scene, parent };
}

const LinePieces = 1;
export function createGrid(width, length, spacing, color) {
    const material = new LineBasicMaterial({
        color: color || 0x888888,
    });

    const geometry = new Geometry();
    for (let i = 0; i <= length; i++) {
        geometry.vertices.push(
            new Vector3(
            -(spacing * width) / 2,
            i * spacing - (spacing * length) / 2,
            0
            )
        );
        geometry.vertices.push(
            new Vector3(
            +(spacing * width) / 2,
            i * spacing - (spacing * length) / 2,
            0
            )
        );
    }

    for (let i = 0; i <= width; i++) {
        geometry.vertices.push(
            new Vector3(
            i * spacing - (spacing * width) / 2,
            -(spacing * length) / 2,
            0
            )
        );
        geometry.vertices.push(
            new Vector3(
            i * spacing - (spacing * width) / 2,
            +(spacing * length) / 2,
            0
            )
        );
    }

    const line = new LineSegments(geometry, material, LinePieces);
    line.receiveShadow = true;
    return line;
}

export function createBackgroundTexture(width, height) {
    function gauss(x, y) {
        return (1 / (2 * Math.PI)) * Math.exp(-(x * x + y * y) / 2);
    }

    const size = width * height;
    const data = new Uint8Array(3 * size);

    const chroma = [1, 1.5, 1.7];
    const max = gauss(0, 0);

    for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
            const u = 2 * (x / width) - 1;
            const v = 2 * (y / height) - 1;

            const i = x + width * y;
            const d = gauss(2 * u, 2 * v) / max;
            let r = (Math.random() + Math.random() + Math.random()) / 3;
            r = (d * 0.5 + 0.5) * r * 0.03;
            r = r * 0.4;

            data[3 * i + 0] = 255 * (d / 15 + 0.05 + r) * chroma[0];
            data[3 * i + 1] = 255 * (d / 15 + 0.05 + r) * chroma[1];
            data[3 * i + 2] = 255 * (d / 15 + 0.05 + r) * chroma[2];
        }
    }

    const texture = new DataTexture(data, width, height, RGBFormat);
    texture.needsUpdate = true;

    return texture;
}

export function zoomToLocation(mouse, controls, pickClipped, moveSpeedDivider = 1) {
    const camera = controls.sceneContext.getActiveCamera();
    const view = controls.sceneContext.view;

    const I = getMousePointCloudIntersection(
        mouse,
        camera,
        controls.viewer,
        controls.sceneContext.pointclouds,
        { pickClipped: pickClipped }
    );

    if (I === null) {
        return;
    }

    let targetRadius = 0;
    {
        const minimumJumpDistance = 0.2;

        const domElement = controls.renderer.domElement;
        const ray = mouseToRay(
            mouse,
            camera,
            domElement.clientWidth,
            domElement.clientHeight
        );

        const nodes = I.pointcloud.nodesOnRay(I.pointcloud.visibleNodes, ray);
        const lastNode = nodes[nodes.length - 1];
        const radius = lastNode.getBoundingSphere(new Sphere()).radius;
        targetRadius = Math.min(controls.sceneContext.view.radius, radius);
        targetRadius = Math.max(minimumJumpDistance, targetRadius);
    }

    const d = view.direction.multiplyScalar(-1).multiplyScalar(targetRadius);
    const cameraTargetPosition = new Vector3().addVectors(
        I.location,
        d
    );
    const animationDuration = 600;
    const easing = TWEEN.Easing.Quartic.Out;

    {
        // animate
        const value = { x: 0 };
        const tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);
        tween.easing(easing);
        controls.tweens.push(tween);

        const startPos = controls.sceneContext.view.position.clone();
        const targetPos = cameraTargetPosition.clone();
        const startRadius = controls.sceneContext.view.radius;
        const targetRadius = cameraTargetPosition.distanceTo(I.location);

        tween.onUpdate(() => {
            const t = value.x;
            view.position.x = (1 - t) * startPos.x + t * targetPos.x;
            view.position.y = (1 - t) * startPos.y + t * targetPos.y;
            view.position.z = (1 - t) * startPos.z + t * targetPos.z;

            view.radius = (1 - t) * startRadius + t * targetRadius;
            controls.viewer.setMoveSpeed(view.radius / moveSpeedDivider);
        });

        tween.onComplete(() => {
            controls.tweens = controls.tweens.filter((e) => e !== tween);
            view.dirty();
        });

        tween.start();
    }
}

export function getMousePointCloudIntersection(
    mouse,
    camera,
    viewer,
    pointclouds,
    params = {}
) {
    const renderer = viewer.webGlRenderer;

    const nmouse = {
        x: (mouse.x / renderer.domElement.clientWidth) * 2 - 1,
        y: -(mouse.y / renderer.domElement.clientHeight) * 2 + 1,
    };

    const pickParams = {};

    if (params.pickClipped) {
    pickParams.pickClipped = params.pickClipped;
    }

    pickParams.x = mouse.x;
    pickParams.y = renderer.domElement.clientHeight - mouse.y;

    const raycaster = new Raycaster();
    raycaster.setFromCamera(nmouse, camera);
    const ray = raycaster.ray;

    let selectedPointcloud = null;
    let closestDistance = Infinity;
    let closestIntersection = null;
    let closestPoint = null;

    for (const pointcloud of pointclouds) {
        const point = pointcloud.pick(viewer, camera, ray, pickParams);

        if (!point) {
            continue;
        }

        const distance = camera.position.distanceTo(point.position);

        if (distance < closestDistance) {
            closestDistance = distance;
            selectedPointcloud = pointcloud;
            closestIntersection = point.position;
            closestPoint = point;
        }
    }

    if (selectedPointcloud) {
        return {
            location: closestIntersection,
            distance: closestDistance,
            pointcloud: selectedPointcloud,
            point: closestPoint,
        };
    }
    return null;
}

export function mouseToRay(mouse, camera, width, height) {
    const normalizedMouse = {
    x: (mouse.x / width) * 2 - 1,
    y: -(mouse.y / height) * 2 + 1,
    };

    const vector = new Vector3(normalizedMouse.x, normalizedMouse.y, 0.5);
    const origin = camera.position.clone();
    vector.unproject(camera);
    const direction = new Vector3().subVectors(vector, origin).normalize();

    const ray = new Ray(origin, direction);

    return ray;
}

export function projectedRadius(
    radius,
    camera,
    distance,
    screenWidth,
    screenHeight
) {
    if (camera instanceof OrthographicCamera) {
        return projectedRadiusOrtho(
            radius,
            camera.projectionMatrix,
            screenWidth,
            screenHeight
        );
    }
    if (camera instanceof PerspectiveCamera) {
        return projectedRadiusPerspective(
            radius,
            (camera.fov * Math.PI) / 180,
            distance,
            screenHeight
        );
    }

    throw new Error("Invalid camera, can't project radius.");
}

export function projectedRadiusPerspective(radius, fov, distance, screenHeight) {
    let projFactor = 1 / Math.tan(fov / 2) / distance;
    projFactor = (projFactor * screenHeight) / 2;

    return radius * projFactor;
}

export function projectedRadiusOrtho(radius, proj, screenWidth, screenHeight) {
    let p1 = new Vector4(0);
    let p2 = new Vector4(radius);

    p1.applyMatrix4(proj);
    p2.applyMatrix4(proj);
    p1 = new Vector3(p1.x, p1.y, p1.z);
    p2 = new Vector3(p2.x, p2.y, p2.z);
    p1.x = (p1.x + 1.0) * 0.5 * screenWidth;
    p1.y = (p1.y + 1.0) * 0.5 * screenHeight;
    p2.x = (p2.x + 1.0) * 0.5 * screenWidth;
    p2.y = (p2.y + 1.0) * 0.5 * screenHeight;
    return p1.distanceTo(p2);
}

// code taken from three.js
// ImageUtils - generateDataTexture()
export function generateDataTexture(width, height, color) {
    const size = width * height;
    const data = new Uint8Array(4 * width * height);

    const r = Math.floor(color.r * 255);
    const g = Math.floor(color.g * 255);
    const b = Math.floor(color.b * 255);

    for (let i = 0; i < size; i++) {
        data[i * 3] = r;
        data[i * 3 + 1] = g;
        data[i * 3 + 2] = b;
    }

    const texture = new DataTexture(data, width, height, RGBAFormat);
    texture.needsUpdate = true;
    texture.magFilter = NearestFilter;

    return texture;
}

export function createChildAABB(aabb, index) {
    const min = aabb.min.clone();
    const max = aabb.max.clone();
    const size = new Vector3().subVectors(max, min);

      if ((index & 0b0001) > 0) {
        min.z += size.z / 2;
      } else {
        max.z -= size.z / 2;
      }

      if ((index & 0b0010) > 0) {
        min.y += size.y / 2;
      } else {
        max.y -= size.y / 2;
      }

      if ((index & 0b0100) > 0) {
        min.x += size.x / 2;
      } else {
        max.x -= size.x / 2;
      }

      return new Box3(min, max);
}

export const screenPass = new (function () {
    this.screenScene = new Scene();
    this.screenQuad = new Mesh(new PlaneBufferGeometry(2, 2, 1));
    this.screenQuad.material.depthTest = true;
    this.screenQuad.material.depthWrite = true;
    this.screenQuad.material.transparent = true;
    this.screenScene.add(this.screenQuad);
    this.camera = new Camera();

    this.render = function (renderer, material) {
      this.screenQuad.material = material;

      renderer.render(this.screenScene, this.camera);
    };
  })();
