import { Box3, Sphere, Vector3 } from "potree/mathtypes";
import { Float32BufferAttribute } from "potree/rendering/bufferattribute";
import { clamp, generateUUID } from "potree/utils/math";

export class SQLineBuffer {
    positions;
    indices;
    count = 0;

    constructor(
      points
    ) {
      this.uuid = generateUUID();

      this.direction = new Float32BufferAttribute(this.#duplicate_mirror(points.map(() => 1)), 1);

      this.count = (points.length-1) * 6;

      const unpacked_points = [];
      points.forEach(point => {
        unpacked_points.push(point.x, point.y, point.z);
        unpacked_points.push(point.x, point.y, point.z);
      });
      const previous = points.map(this.#relative(-1));
      const unpacked_previous = [];
      previous.forEach(point => {
        unpacked_previous.push(point.x, point.y, point.z);
        unpacked_previous.push(point.x, point.y, point.z);
      });
      const next = points.map(this.#relative(+1));
      const unpacked_next = [];
      next.forEach(point => {
        unpacked_next.push(point.x, point.y, point.z);
        unpacked_next.push(point.x, point.y, point.z);
      });
      this.positions = new Float32BufferAttribute(unpacked_points, 3);
      this.previous = new Float32BufferAttribute(unpacked_previous, 3);
      this.next = new Float32BufferAttribute(unpacked_next, 3);

      this.indices = this.#createIndices(points.length);
    }

    #duplicate_mirror(array) {
      const out = [];
      array.forEach(element => {
        out.push(element, -element);
      });

      return out;
    }
    #relative(offset) {
      return (_point, index, list) => {
        index = clamp(index + offset, 0, list.length-1)
        return list[index]
      }
    }
    #createIndices(length) {
      const indices = new Uint16Array(length * 6)
      let c = 0, index = 0
      for (let j=0; j<length; j++) {
        const i = index
        indices[c++] = i + 0
        indices[c++] = i + 1
        indices[c++] = i + 2
        indices[c++] = i + 2
        indices[c++] = i + 1
        indices[c++] = i + 3
        index += 2
      }
      return indices
    }

    createRenderBuffer(gl) {
      const vertexArray = gl.createVertexArray();
      gl.bindVertexArray(vertexArray);

      const attributes = [];
      attributes.push(this.#createAttribute(gl, this.positions, 0));
      attributes.push(this.#createAttribute(gl, this.direction, 1));
      attributes.push(this.#createAttribute(gl, this.next, 2));
      attributes.push(this.#createAttribute(gl, this.previous, 3));

      const indexBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
      gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indices, gl.STATIC_DRAW);


      gl.bindBuffer(gl.ARRAY_BUFFER, null);
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
      gl.bindVertexArray(null);

      const buffer = {
        vertexArray: vertexArray,
        attributes: attributes,
        indexBuf: indexBuffer
      }

      return buffer;
    }
    #createAttribute(gl, attribute, location, type) {
      const buffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
      gl.bufferData(gl.ARRAY_BUFFER, attribute.array, gl.STATIC_DRAW);

      gl.vertexAttribPointer(
        location,
        attribute.itemSize,
        type || gl.FLOAT,
        attribute.normalized,
        0,
        0
      );
      gl.enableVertexAttribArray(location);

      return {
        buffer,
        attribute,
        location,
        type
      };
    }
}


export class SQBoxBuffer {
  vertices;
  indices;

  constructor(
    width = 1,
    height = 1,
    depth = 1
  ) {
    this.uuid = generateUUID();
    this.isSQBoxBuffer = true;

    const half_width = width / 2.0;
    const half_height = height / 2.0;
    const half_depth = depth / 2.0;

    const vertices = [
      -half_width, half_height, -half_depth,
      half_width, half_height, -half_depth,
      half_width, -half_height, -half_depth,
      -half_width, -half_height, -half_depth,

      -half_width, half_height, half_depth,
      half_width, half_height, half_depth,
      half_width, -half_height, half_depth,
      -half_width, -half_height, half_depth
    ];
    this.vertices = new Float32BufferAttribute(vertices, 3);

    this.indices = new Uint8Array([
      // BACK
      0,1,2,
      0,2,3,

      // LEFT
      0,7,4,
      0,3,7,

      // RIGHT
      5,2,1,
      5,6,2,

      // BOTTOM
      3,2,6,
      3,6,7,

      // TOP
      0,5,1,
      0,4,5,

      // FRONT
      4,6,5,
      4,7,6,
    ]); // 2 for each side.

    this.boundingSphere = new Sphere(new Vector3(), Math.max(half_depth, half_height, half_width));
    this.boundingBox = new Box3(new Vector3(-half_width, -half_height, -half_depth), new Vector3(half_width, half_height, half_depth));
  }

  createRenderBuffer(gl) {
    // Vertex array manages the context for the rest of the data.
    const vertexArray = gl.createVertexArray();
    gl.bindVertexArray(vertexArray);

    // All the vertices of the mesh.
    const vertices = this.vertices;

    const positionsBuffer = gl.createBuffer(); // The buffer is essentially just an Id.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionsBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices.array, gl.STATIC_DRAW);


    // All the incides (These are groups of 3 that make up triangles)
    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this.indices, gl.STATIC_DRAW);

    const normalized = vertices.normalized;
    const attributeLocation = 0;
    gl.vertexAttribPointer(
      attributeLocation,
      vertices.itemSize,
      gl.FLOAT,
      normalized,
      0,
      0
    );
    gl.enableVertexAttribArray(attributeLocation);

    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
    gl.bindVertexArray(null);

    const buffer = {
      vertexArray: vertexArray,
      positionsBuffer: positionsBuffer,
      indexBuf: indexBuffer
    }

    return buffer;
  }
}